import { API_URLS } from "../../constants";
import { POST, appStorage } from "../../helpers";



let authentication = appStorage.getItem('authentication') || {};
let authParams = {
  authkey: authentication.authkey || "",
  key: authentication.key || "",
}



export function fetchSendGetHistoryPatientsByUser({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.GetHistoryPatientsByUser,
    data: {
      ...data,
      // id: "c2b8cdaf-bec5-4a7b-b24c-57ecd1567881",
      id: data.id,
      ...authParams,
    },
    success: () => {
      success && success();
    },
  });
}
