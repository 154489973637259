import React, { memo, useState } from "react";

import {
    Box,
    Card,
    Grid,
    Typography,
} from "@mui/material";

import { fields } from '../../app/thema';
import { PatientsList } from "./components/PatientsList";


const RecentComponent = () => {
    return <Grid
        className="no-print"
        sx={{
            mt: '15px',
            overflow: "auto",
        }}
        container
    >
        <Grid
            item
            // xs={1}
            md={0}
            lg={1}
        />
        <Grid
            item
            md={12}
            lg={10}
            sx={{
                overflow: "auto",
            }}
        >
            <Card
                sx={{
                    mx: '5px',
                    py: '15px',
                    px: '10px',
                    pr: "30px",
                    borderRadius: '10px'
                }}
                variant="outlined"
            >
                <Grid
                    container spacing={2}
                >
                    <Grid
                        style={{
                            textAlign: 'center',
                        }}
                        item
                        xs={12}
                    >
                        <Typography
                            variant="h4"
                            color={fields.collection2.borderColor}
                        >
                            Мои пациенты
                        </Typography>
                    </Grid>
                    <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
                        <PatientsList />
                    </Box>
                </Grid>
            </Card>
        </Grid>
        <Grid
            item
            md={0}
            lg={1}
        />
    </Grid>;
}

export const Recent = memo(RecentComponent);
