import { toast } from 'react-toastify';


/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertSuccess(msg, props = {}) {
    toast.success(msg, props);
}

/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertInfo(msg, props = {}) {
    toast.info(msg, props);
}

/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertWarn(msg, props = {}) {
    toast.warn(msg, props);
}

/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertError(msg, props = {}) {
    toast.error(msg, props);
}

export {
    alertSuccess, alertInfo, alertWarn, alertError
}