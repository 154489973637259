import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
} from '../api/QueryHandler';
import {
    MilitaryPartGET, MilitaryUnitGET, MilitaryCompanyGET, MilitaryPlatoonGET, MilitaryDepartmentGET,
    MilitaryRankGET, MilitaryHandbooksGET, Handbook300POST, Handbook200GET,
    MilitaryPartPOST,
    EvacuationLocationsPOST,
    LocationsPOST,
    TechAsyncPOST,
} from '../helper/UrlController';
import {
    ResponseAlert, ItemHandbookByFullName, ItemHandbookLocation
} from '../models/Response';


/**
 * API для получения списка Место оказания медицинской помощи
 * @returns Модель ответа
 */
async function LocationsAsync() {
    let model = {
        alert: {},
        items: [],
    };
    try {
        let res = await PostFetchAsync(LocationsPOST().url, {});
        // Валидация
        console.log(res);
        if (!ResponseCheck(res)) {
            return res;
        }

        model.items = res.items;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка Куда эвакуирован
 * @returns Модель ответа
 */
async function EvacuationLocationsAsync() {
    let model = {
        alert: {},
        items: [],
    };
    try {
        let res = await PostFetchAsync(EvacuationLocationsPOST().url, {});
        // Валидация
        console.log(res);
        if (!ResponseCheck(res)) {
            return res;
        }

        model.items = res.items;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка военских частей
 * @returns Модель ответа
 */
async function MilitaryPartAsync() {
    let model = {
        alert: {},
        items: [],
    };
    try {
        let res = await PostFetchAsync(MilitaryPartPOST().url, {});
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.items = res.items;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка военских частей
 * @returns Модель ответа
 */
async function MilitaryPartAsync2() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(MilitaryPartGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка подразделений
 * @returns Модель ответа
 */
async function MilitaryUnitAsync() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(MilitaryUnitGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка рот
 * @returns Модель ответа
 */
async function MilitaryCompanyAsync() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(MilitaryCompanyGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка взводов
 * @returns Модель ответа
 */
async function MilitaryPlatoonAsync() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(MilitaryPlatoonGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка отделений
 * @returns Модель ответа
 */
async function MilitaryDepartmentAsync() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(MilitaryDepartmentGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка воинских званий
 * @returns Модель ответа
 */
async function MilitaryRankAsync() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(MilitaryRankGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения справочников для личного состава
 * @returns 
 */
async function MilitaryHandbooksAsync() {
    let model = {
        alert: {},
        result: {},
    };
    try {
        let res = await GetFetchAsync(MilitaryHandbooksGET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = new ItemHandbookByFullName(
            res.result.militaryPart, res.result.militaryUnit, res.result.militaryCompany, res.result.militaryPlatoon,
            res.result.militaryDepartment, res.result.militaryRankByState, res.result.militaryWus
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка локаций
 * @returns 
 */
async function Handbook300Async() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await PostFetchAsync(Handbook300POST().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = new ItemHandbookLocation(
            res.result.locationWhereInjury, res.result.locationWhere, res.result.locationMedicalAssistance
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка локаций
 * @returns 
 */
async function TechAsync() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await PostFetchAsync(TechAsyncPOST().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model = res;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения списка локаций
 * @returns 
 */
async function Handbook200Async() {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let res = await GetFetchAsync(Handbook200GET().url);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.result = new ItemHandbookLocation(
            res.result.locationWhereDeath, res.result.locationWhere
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

export {
    MilitaryPartAsync, MilitaryUnitAsync, MilitaryCompanyAsync, MilitaryPlatoonAsync,
    MilitaryDepartmentAsync, MilitaryRankAsync, MilitaryHandbooksAsync, Handbook300Async,
    Handbook200Async,
    EvacuationLocationsAsync,
    LocationsAsync,
    TechAsync,
}