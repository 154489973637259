import { urlDef } from './UrlRouter';
import { appStorage } from '../../helpers';


/**
 * Проверка авторизации пользователя
 * @param { Object } navigate навигация
 */
function UserCheck(navigate) {
    let authentication = appStorage.getItem('authentication');
    if (!authentication) navigate(urlDef);
}


export {
    UserCheck,
}