import React, { useState } from 'react';

import { Stack } from '@mui/material';

import { TextFld } from '../components/Fields';
import { IconBtn } from '../components/Buttons';
import { AutoCompleteCbx } from '../components/ComboBoxs';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


/**
 * Виджет выпадающий список с поиском и редактированием
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
function ComboBoxEdit(props) {
    let model = props.model;
    
    return (
        <>
            {props.isEdit
                ? <>
                    <Stack
                        direction="row"
                        spacing={1}>
                        <TextFld
                            thema={model.textFld.thema} name={model.textFld.name} size={model.textFld.size} 
                            type={model.textFld.type} variant={model.textFld.variant}
                            label={model.textFld.label} value={props.valueEdit} isDisabled={props.isDisabled}
                            handleBlur={props.handleBlur} handleChange={props.handleChangeEdit} handleClickLabel={props.handleClickLabel}
                            touched={props.touchedEdit} error={props.errorEdit}
                            required={!!props.required}
                        />
                        <IconBtn
                            thema={model.iconBtn.thema} icon={<SearchOutlinedIcon />} isDisabled={props.isDisabled}
                            handleClick={props.handleClick}
                        />
                    </Stack>
                </>
                : <>
                    <Stack
                        direction="row"
                        spacing={1}>
                        <AutoCompleteCbx
                            thema={model.autoCompleteCbx.thema} name={model.autoCompleteCbx.name} size={model.autoCompleteCbx.size} 
                            elems={props.elems} variant={model.autoCompleteCbx.variant}
                            label={model.autoCompleteCbx.label} value={props.values} val={props.val} isDisabled={props.isDisabled}
                            handleBlur={props.handleBlur} handleChange={props.handleChange} setFieldValue={props.setFieldValue} 
                            handleSetState={props.handleSetState} handleClickLabel={props.handleClickLabel}
                            touched={props.touched} error={props.error}
                            required={!!props.required}
                        />
                        <IconBtn
                            thema={model.iconBtn.thema} icon={<EditOutlinedIcon />} isDisabled={props.isDisabled}
                            handleClick={props.handleClick}
                        />
                    </Stack>
                </>
            }
        </>
    )
}

export {
    ComboBoxEdit
}