import React from 'react';

import { cards } from '../app/thema';
import {
    urlFormaDeath, urlFormaInjury, urlFormaDiseases, urlFormaTrauma, urlFormaNuclear,
    urlFormaBacterial, urlFormaChemical, urlFormaBurn, urlFormaFrostbite, urlFormaInfectious,
} from '../common/helper/UrlRouter';

import { Grid, Avatar } from '@mui/material';

import { BtnCard, BtnCardImgSm, BtnCardImg } from '../components/Cards';

import { PSO } from "../features/PSO"


const model = {
    injury: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/injury.jpg',
        title: 'Ранение',
        href: urlFormaInjury
    },
    trauma: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/trauma.jpg',
        title: 'Травма',
        href: urlFormaTrauma
    },
    diseases: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/diseases.jpg',
        title: 'Заболевания',
        href: urlFormaDiseases
    },
    nuclear: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/nuclear.jpg',
        title: 'Ядерное',
        href: urlFormaNuclear
    },
    bacterial: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/bacterial1.jpg',
        title: 'Бактериальное',
        href: urlFormaBacterial
    },
    chemical: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/chemical.jpg',
        title: 'Химическое',
        href: urlFormaChemical
    },
    burn: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/burn.jpg',
        title: 'Ожог',
        href: urlFormaBurn
    },
    frostbite: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/frostbite.jpg',
        title: 'Отморожение',
        href: urlFormaFrostbite
    },
    infectious: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/infectious.jpg',
        title: 'Инфекция',
        href: urlFormaInfectious
    },
    death: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/death.jpg',
        title: 'Форма 200',
        href: urlFormaDeath
    },
}

/**
 * Виджет выбора типа форм регистрации пациента
 * @returns виджет
 */
export default function SelectorForms() {
    return (
        <>
            <Grid
                className='no-print'
                sx={{
                    mt: '15px',
                }}
                container
            >
                <Grid
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                    }}
                    item xs={0} sm={0} md={0} lg={2} xl={2}
                />
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <Grid
                        container spacing={2}
                        sx={{ px: 1 }}
                    >
                        <Grid
                            item xs={12} sm={12} md={12} lg={6} xl={6}
                        >
                            <BtnCardImgSm
                                thema={model.injury.thema} fontSize={model.injury.fontSize} img={model.injury.img}
                                title={model.injury.title} href={model.injury.href}
                            />
                        </Grid>
                        <Grid
                            item xs={12} sm={12} md={12} lg={6} xl={6}
                        >
                            <BtnCardImgSm
                                thema={model.trauma.thema} fontSize={model.trauma.fontSize} img={model.trauma.img}
                                title={model.trauma.title} href={model.trauma.href}
                            />
                        </Grid>
                        <Grid
                            item xs={12} sm={12} md={12} lg={6} xl={6}
                        >
                            <BtnCardImgSm
                                thema={model.diseases.thema} fontSize={model.diseases.fontSize} img={model.diseases.img}
                                title={model.diseases.title} href={model.diseases.href}
                            />
                        </Grid>
                        {/* <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.nuclear.thema} fontSize={model.nuclear.fontSize} img={model.nuclear.img}
                            title={model.nuclear.title} href={model.nuclear.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.bacterial.thema} fontSize={model.bacterial.fontSize} img={model.bacterial.img}
                            title={model.bacterial.title} href={model.bacterial.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.chemical.thema} fontSize={model.chemical.fontSize} img={model.chemical.img}
                            title={model.chemical.title} href={model.chemical.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.burn.thema} fontSize={model.burn.fontSize} img={model.burn.img}
                            title={model.burn.title} href={model.burn.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.frostbite.thema} fontSize={model.frostbite.fontSize} img={model.frostbite.img}
                            title={model.frostbite.title} href={model.frostbite.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.infectious.thema} fontSize={model.infectious.fontSize} img={model.infectious.img}
                            title={model.infectious.title} href={model.infectious.href}
                        />
                    </Grid> */}
                        <Grid
                            item xs={12} sm={12} md={12} lg={6} xl={6}
                        >
                            <BtnCardImgSm
                                thema={model.death.thema} fontSize={model.death.fontSize} img={model.death.img}
                                title={model.death.title} href={model.death.href}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                    }}
                    item xs={0} sm={0} md={0} lg={2} xl={2}
                />
            </Grid>
            <PSO />
        </>
    )
}
