import React, { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Stack,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    DialogContentText,
    createTheme,
    ThemeProvider,
} from '@mui/material';

import {
    selectPatientData,
    sendUpdatePersonnel,
    updatePatientData,
} from "../../historySlice";


import { ruRU } from '@mui/x-date-pickers';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import 'dayjs/locale/ru';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
dayjs.locale("ru");
const theme = createTheme(
    ruRU,
);

const PatientInfoEditComponent = ({ openModal, handleCloseModal }) => {
    const patientData = useSelector(selectPatientData);
    const dispatch = useDispatch();

    console.log(patientData);

    return <Dialog
        fullWidth
        open={openModal}
        onClose={handleCloseModal}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const modalData = Object.fromEntries(formData.entries());

                let fioArray = [modalData.surName || "", modalData.name || "", modalData.patronymic || ""];
                let fio = fioArray.join(" ");
                fio = fio.trim();

                console.log(modalData);
                // console.log(modalData.birthday);
                // console.log(dayjs(modalData.birthday ?? undefined));
                // console.log(new Date(dayjs(modalData.birthday ?? undefined)));

                // let birthday;

                // if (modalData.birthday) {
                //     birthday = new Date(dayjs(modalData.birthday ?? undefined));
                //     birthday = birthday.toISOString();

                // } else {
                //     birthday = null;
                // }

                // console.log(birthday);


                dispatch(sendUpdatePersonnel({
                    data: {
                        id: patientData.id,
                        ...modalData,
                        fio,
                        // birthday
                    },
                    success: () => {

                    },
                }));
                dispatch(updatePatientData({
                    ...modalData,
                    fio,
                }));
                handleCloseModal();
            },
        }}
    >
        {/* <DialogTitle>Subscribe</DialogTitle> */}
        <DialogContent>
            <DialogContentText>
                <Stack
                    spacing={2}
                >

                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Фамилия"
                        variant="outlined"
                        name="surName"
                        defaultValue={patientData.surName}
                        size="small"
                    />

                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Имя"
                        variant="outlined"
                        name="name"
                        defaultValue={patientData.name}
                        size="small"
                    />

                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Отчество"
                        variant="outlined"
                        name="patronymic"
                        defaultValue={patientData.patronymic}
                        size="small"
                    />

                    {/* <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker
                                label="Дата рождения"
                                name="birthday"
                                defaultValue={dayjs(patientData.birthday)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                    },
                                    field: { clearable: true },
                                }}

                            />
                        </LocalizationProvider>
                    </ThemeProvider> */}
                </Stack>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseModal} variant="contained">Закрыть</Button>
            <Button type="submit" variant="contained">Сохранить</Button>
        </DialogActions>
    </Dialog>
        ;
}

export const PatientInfoEdit = memo(PatientInfoEditComponent);
