import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { LicenseInfo } from '@mui/x-license';


import Router from "./routes";

import { AlertToast } from '../components/Alerts';
import { UserCheck } from '../common/helper/Cheking';

import { appStorage } from '../helpers';

import AppBarMenu from '../widgets/AppBarMenu';
import LeftMenu from '../widgets/LeftMenu';

import "./styles.css";

LicenseInfo.setLicenseKey('415b41462e54b28fba039f1546a5734cTz0xMDAzMzIsRT0xNzYxMDY0MzM4MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

const titles = {
    history: 'Этапы эвакуации',
    registration: 'Приемно-сортировочное отделение',
    recent: 'Мои пациенты',
    hospital: 'Госпитальное отделение',
    evacuation: 'Эвакуационное отделение',
    management: 'Управление сервером',
    export: 'Экспорт данных',
    personnel: 'Личный состав',
};

export default function App() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [menuOpen, setMenuOpen] = useState(false);
    const [role, setRole] = useState('');

    const getTitleFromPathname = pathname => {
        const pahtArray = pathname ? pathname.split("/") : ["", ""];
        const title = pahtArray[1] ? titles[pahtArray[1]] : "";

        return title ? title.trim() : "";
    }

    useEffect(() => {
        UserCheck(navigate);
        let buf = appStorage.getItem('authentication');
        buf && setRole(buf.role);
    }, []);


    const toggleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const toggleDrawerClose = () => {
        setMenuOpen(false);
    };

    const pageTitle = getTitleFromPathname(pathname);

    return (
        <>
            {pageTitle && <div className="no-print">
            
                <AppBarMenu
                    headerValue={pageTitle}
                    handleClick={toggleDrawerOpen}
                />
                <LeftMenu
                    open={menuOpen}
                    role={role}
                    handleClick={toggleDrawerClose}
                />
            </div>}
            <Router /><div className="no-print">
            <AlertToast /> </div>
        </>
    )
}
