import { TextField } from "@mui/material";

export const ModalTextField = ({ name, sx = {}, ...props }) => {
    return <TextField
        margin="dense"
        size="small"
        id={name}
        name={name}
        type="text"
        variant="standard"
        sx={{
            m: 0,
            ml: 1,
            width: "80px",
            ...sx,
        }}
        {...props}
    />;
}
