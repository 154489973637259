import {
    Box,
    Typography,
} from "@mui/material";

import {
    selectPrintList,
} from "../../evacuationSlice";

import { useSelector } from "react-redux";

function PrintListItemHeader({ width, styles, children }) {
    return <Box
        sx={{
            width: width + "px",
            p: "2px",
            ...styles
        }}>
        <Typography variant="body2"
            sx={{
                fontSize: "2.3mm",
                fontWeight: 600,
            }}>{children}</Typography>
    </Box>;
}

function PrintListItem({ width, styles, children }) {
    return <Box
        sx={{
            width: width + "px",
            borderRight: "1px solid black",
            p: "2px",
            ...styles
        }}>
        <Typography variant="body2" sx={{ fontSize: "2.3mm" }}>{children}</Typography>
    </Box>;
}

export function PrintList() {
    let printList = useSelector(selectPrintList);

    if (!printList.length) {
        return null;
    }

    return (
        <Box
            className="page print"
            sx={{
                ml: 2,
                pt: 2,
                // mb: 2,
                height: "auto",
                width: "200mm",
            }}
        >
            <style type="text/css" media="print">
                {" @page { size: portrait; } "}
            </style>
            <Box
                sx={{
                    width: "100%",

                    display: "flex",
                }}>
                <PrintListItemHeader width="35">№</PrintListItemHeader>
                <PrintListItemHeader width="220">ФИО</PrintListItemHeader>
                <PrintListItemHeader width="80">Воинское звание</PrintListItemHeader>
                <PrintListItemHeader width="40">№ части</PrintListItemHeader>
                <PrintListItemHeader width="90">Лечащий врач</PrintListItemHeader>
                <PrintListItemHeader width="50">Вид эвакуации</PrintListItemHeader>
                <PrintListItemHeader width="50">Палата</PrintListItemHeader>
                <PrintListItemHeader width="60">Направление эвакуации</PrintListItemHeader>
                <PrintListItemHeader width="160">Примечание</PrintListItemHeader>
            </Box>
            <Box>
                {printList.map((item, index) => <Box
                    sx={{
                        width: "100%",
                        border: "1px solid black",
                        display: "flex",
                    }}>
                    <PrintListItem width="35">{index + 1}</PrintListItem>
                    <PrintListItem width="220">{item.fio}</PrintListItem>
                    <PrintListItem width="80">{item.jp_actual_rank}</PrintListItem>
                    <PrintListItem width="40">{item.jp_military_unit}</PrintListItem>
                    <PrintListItem width="90">{item.h300_doctor}</PrintListItem>
                    <PrintListItem width="50">{item.h300_evacuation_type}</PrintListItem>
                    <PrintListItem width="50">{item.h300_hospital_ward}</PrintListItem>
                    <PrintListItem width="60">{item.h300_referral}</PrintListItem>
                    <PrintListItem width="160">{item.h300_notes}</PrintListItem>
                </Box>
                )}
            </Box>
        </Box>
    );
}