import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
} from '@mui/material';

import "./styles.css";


const PrintTpl_6Component = ({ printData }) => {
    let { patientData, currentHistoryItem, currentEvent, modalData } = printData;

    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };

    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    let currentDate = new Date().toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });

    return <>

        <div className="page print page-nobreak">
            <style type="text/css" media="print">
                {" @page { size: portrait; } "}
            </style>
            <div className="htitle">Эвакуационная карта</div>
            <table className="table_6">
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Ф.И.О., дата рождения</td>
                        <td>{patientData.fio} {patientData.birthday}, {patientData.militaryRank} {patientData.militaryUnit} {patientData.militaryUnitCode} {patientData.suid}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Диагноз</td>
                        <td>{currentHistoryItem.diagnosisFinal || currentHistoryItem.diagnosisClinical || currentHistoryItem.disease}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Дата ранения (травмы)</td>
                        <td>{currentEvent.eventDate ? new Date(currentEvent.eventDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : ""}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Дата поступления в ВПСГ</td>
                        <td>{currentHistoryItem.hospitalizationDate}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Операции (все)</td>
                        <td>{currentHistoryItem.helping}</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Назначения в период эвакуации</td>
                        <td>{currentHistoryItem.pharmacotherapy}</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Риски</td>
                        <td>{currentHistoryItem.risks}</td>
                    </tr>

                </tbody>
            </table>

        </div>
    </>;
}

export const PrintTpl_6 = memo(PrintTpl_6Component);
