import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
} from '@mui/material';

import "./styles.css"



const PrintTpl_5Component = ({ printData }) => {


    console.log(printData);

    let { patientData, currentHistoryItem, currentEvent } = printData;


    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };


    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    let currentDate = new Date().toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });


    currentEvent = {
        ...currentEvent,
        eventDate: currentEvent.eventDate ? new Date(currentEvent.eventDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "",
    }

    let jobArray = [];

    if (patientData.militaryUnit) {
        jobArray.push(patientData.militaryUnit);
    }
    if (patientData.militaryUnitCode) {
        jobArray.push(patientData.militaryUnitCode);
    }
    // if(patientData.militaryDep){
    //     jobArray.push(patientData.militaryDep);
    // }

    const job = jobArray.join(", ");


    let disease = currentHistoryItem.disease;
    let diagnosisClinical = currentHistoryItem.diagnosisClinical;
    let diagnosisFinal = currentHistoryItem.diagnosisFinal;

    let shorFormat1 = false;
    if (disease.length > 300) {
        shorFormat1 = true;
    }

    let shorFormat2 = false;
    if (diagnosisClinical.length > 300) {
        shorFormat2 = true;
    }

    let shorFormat3 = false;
    if (diagnosisFinal.length > 300) {
        shorFormat3 = true;
    }

    return <>

        <style type="text/css" media="print">
            {" @page { size: portrait; } "}
        </style>
        <div className="TWpage print">
            <div className="TWform-number">Форма № 003/у (форма 12)</div>
            <table className="TWmain-table">
                <tbody>
                    <tr>
                        <td className="TWhalf">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbheader">МЕДИЦИНСКАЯ КАРТА СТАЦИОНАРНОГО БОЛЬНОГО № {currentHistoryItem.number}{/*patientData.suid*/} По архиву № ______ {/*currentHistoryItem.number*/}</td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>1. Наименование лечебной организации</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent TWtextCenter">{currentHistoryItem.location}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>2. Фамилия, имя, отчество</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent TWtextCenter">{patientData.fio}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>3. Дата рождения</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "34mm" }}> {patientData.birthday}</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "67mm" }}> <b>4. Пол:</b> м., ж.,</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>5. Удостоверение (паспорт), военный билет, </b></div>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle" style={{ "marginLeft": "3mm" }}>номер жетона</div>
                                            <div className="TWbcontent" style={{ "marginLeft": "30mm", "marginTop": "2px" }}> {patientData.suid}</div>
                                        </td>
                                    </tr> */}

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>серия</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "13mm" }}></div>

                                            <div className="TWbtitle" style={{ "marginLeft": "38mm" }}><b>№</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "50mm" }}> {patientData.suid}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>6. Дата поступления на военную службу</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "74mm" }}></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>7. Воинское звание</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "38mm" }}>{patientData.militaryRank}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>8. Дата и время поступления</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "56mm" }}>{currentHistoryItem.hospitalizationDate}</div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>9. Кем направлен больной</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent TWtextCenter"></div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>10. Доставлен по экстренным показаниям:</b> да, нет
                                            </div>
                                        </td>
                                    </tr>



                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>11. Начало заболевания, получения травмы:</b> время,
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle">дата</div>
                                            <div className="TWbcontent" style={{ "marginLeft": "12mm" }}> {currentEvent.eventDate}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>12. Название страховой комп., организации</b></div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent TWtextCenter"></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>13. № страхового полиса, договора</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "63mm" }}></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>14. Вид оплаты:</b> бюджет, ОМС, ДМС, платные услуги,</div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle">другое</div>
                                            <div className="TWbcontent" style={{ "marginLeft": "15mm" }}></div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>



                        </td>




                        <td className="TWbright">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Группа крови</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "26mm" }}></div>

                                            <div className="TWbtitle" style={{ "marginLeft": "38mm" }}><b>Резус-принадлежность</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "82mm" }}></div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle">Дата</div>
                                            <div className="TWbcontent" style={{ "marginLeft": "11mm" }}></div>

                                            <div className="TWbtitle" style={{ "marginLeft": "38mm" }}>Подпись врача</div>
                                            <div className="TWbcontent" style={{ "marginLeft": "70mm" }}></div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Побочное действие лекарств</b></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent" style={{ "marginLeft": "1mm" }}></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>Наличие документов:</b> форма 100, амб. карта, направление,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">медкнижка, продаттестат, выписка из истории болезни, листок</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">нетрудоспособности, страховой полис.</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Помещён в</b> "&nbsp;&nbsp;&nbsp;" час. "&nbsp;&nbsp;&nbsp;" мин. в</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "70mm", "paddingRight": "12mm" }}>отделение</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle" style={{ "marginLeft": "37mm" }}>Дата</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "66mm" }}>Дней лечения</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Переведён в</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "53mm", "paddingRight": "3mm" }}>отд. </div>
                                            <div className="TWbtitle" style={{ "marginLeft": "73mm" }}>&nbsp;&nbsp;&nbsp;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Переведён в</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "53mm", "paddingRight": "3mm" }}>отд. </div>
                                            <div className="TWbtitle" style={{ "marginLeft": "73mm" }}>&nbsp;&nbsp;&nbsp;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Переведён в</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "53mm", "paddingRight": "3mm" }}>отд. </div>
                                            <div className="TWbtitle" style={{ "marginLeft": "73mm" }}>&nbsp;&nbsp;&nbsp;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Переведён в</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "53mm", "paddingRight": "3mm" }}>отд. </div>
                                            <div className="TWbtitle" style={{ "marginLeft": "73mm" }}>&nbsp;&nbsp;&nbsp;</div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>25. Переведён в:</b> гарнизонный госпиталь, базовый госпиталь,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">окружной госпиталь, центр, военно-лечебную организацию,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">организацию гражданского здравоохранения, прочие.</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>26. Дата убытия</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "30mm" }}></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>27. Длительность лечения (в днях)</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "61mm" }}></div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>28. Исход заболевания (подчеркнуть):</b> выписан с</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">выздоровлением, с улучшением, без перемен, с ухудшением,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">умер; переведён в лечебную организацию (какую).</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent"></div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>




                        </td>
                    </tr>
                    <tr>
                        <td className="TWhalf" colSpan="2">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>15. Место постоянного проживания, тел.</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "72mm" }}>{patientData.phoneNumber}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>16. Фамилия и адрес доверенного лица, тел.</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "79mm" }}></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>17. Место службы (работы), должность, тел.</b></div>
                                            <div className="TWbcontent" style={{ "marginLeft": "77mm" }}>{job}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>18. Категория состава:</b> военнослужащий МО РФ (призыв, контракт); офицер
                                                (прапорщик)
                                                запаса (в отставке); член семьи</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">офицера кадра, офицер запаса (в отставке); военнослужащий ФОИВ (Росгвардия, МЧС,
                                                ФСБ, военнослужащий другого</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">ФОИВ), член семьи военнослужащего ФОИВ; военный судья, член семьи военного судьи;
                                                гражданский персонал ВС РФ;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">прочие в т.ч. за плату.</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>19. Льготная категория:</b> участние ВОВ, участник боевых действий, подвергался
                                                радиоционному воздействию, ветеран</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">подразделения особого риска, Герой РФ, Герой СССР, полный кавалер орденов Славы,
                                                член семьи Героя РФ, Героя СССР,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle">полного кавалера орденов Славы, инвалидность</div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>20. Госпитализация:</b> первичная, повторная</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>21. Диагноз направившей организации</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle" style={{ "right": "0" }}><b>Код
                                                [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</b>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle" style={{ "whiteSpace": "wrap" }}><b>22. Диагноз при поступлении</b></div>
                                            <div className="TWbcontent-Exp" style={{ "textIndent": "52mm" }}>{disease}</div>

                                        </td>
                                    </tr>
                                    {!shorFormat1 && <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbcontent"></div>
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>Дата</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "116mm" }}>Подпись</div>
                                            <div className="TWbtitle" style={{ "right": "0" }}><b>Код
                                                [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</b>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>23. Диагноз клинический</b></div>
                                            <div className="TWbcontent-Exp" style={{ "textIndent": "52mm" }}>
                                                {diagnosisClinical}
                                            </div>
                                        </td>
                                    </tr>
                                    {!shorFormat2 && <>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                    </>}
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>Дата</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "116mm" }}>Подпись</div>
                                            <div className="TWbtitle" style={{ "right": "0" }}><b>Код
                                                [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</b>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>24. Диагноз заключительный клинический. Основной:</b></div>
                                            <div className="TWbcontent-Exp" style={{ "textIndent": "90mm" }}>
                                                {diagnosisFinal}
                                            </div>
                                        </td>
                                    </tr>
                                    {!shorFormat3 && <><tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"></div>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                    </>}
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>Дата</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "116mm" }}>Подпись</div>
                                            <div className="TWbtitle" style={{ "right": "0" }}><b>Код
                                                [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</b>
                                            </div>
                                        </td>
                                    </tr>

                                    {!shorFormat1 && !shorFormat2 && !shorFormat3 && <>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"><b>Осложнени основного:</b></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>Дата</div>
                                                <div className="TWbtitle" style={{ "marginLeft": "116mm" }}>Подпись</div>
                                                <div className="TWbtitle" style={{ "right": "0" }}><b>Код
                                                    [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</b>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"><b>Сопутствующий:</b></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="TWbborder-flex">
                                                <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>Дата</div>
                                                <div className="TWbtitle" style={{ "marginLeft": "116mm" }}>Подпись</div>
                                                <div className="TWbtitle" style={{ "right": "0" }}><b>Код
                                                    [&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</b>
                                                </div>
                                            </td>
                                        </tr>
                                    </>}

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>С распорядком и правилами поведения в военном госпитале ознакомлен</b></div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>ОБСЛЕДОВАНИЕ:</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "33mm" }}>Общий анализ крови</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "76mm" }}>Биохимический анализ крови</div>
                                            <div className="TWbborder-flex" style={{ "width": "100%", "marginLeft": "139mm" }}>
                                                <div className="TWbtitle"><b>Лечащий врач</b> {currentHistoryItem.doctor}</div>
                                            </div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle" style={{ "marginLeft": "7mm" }}>Общий анализ мочи</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "48mm" }}>RW, ВИЧ, гепатит B, C</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "96mm" }}>группа крови, резус</div>
                                            <div className="TWbborder-flex" style={{ "width": "100%", "marginLeft": "139mm" }}>
                                                <div className="TWbtitle"><b>Этаж</b> {currentHistoryItem.hospitalWard}</div>
                                                <div className="TWbtitle" style={{ "marginLeft": "33mm" }}><b>Палата</b></div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>КТ:</b> голова, шея, грудь, живот, таз, бедро позвоночник: ШОП; ГОП; ПОП;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Рентгенография:</b> голова в 2-х проекциях; ОГК в 2-х проекциях; ОБП в 2-х
                                                проекциях;
                                                ТАЗ</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Верхняя&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;левая:</b> плечевой с. /плечо
                                                /локтевой с. /предплечье /лучезапястный с.
                                                /кисть </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>конечность правая:</b> плечевой с. /плечо /локтевой с. /предплечье /лучезапястный
                                                с.
                                                /кисть</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Нижняя&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;левая:</b> ягодичная обл. /бедро
                                                /коленный с. /голень /голеностоп с.
                                                /стопа</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>конечность правая:</b> ягодичная обл. /бедро /коленный с. /голень /голеностоп с.
                                                /стопа
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </td>


                    </tr>





                </tbody>
            </table>
        </div>
        <div className="TWpage print">
            <table className="TWmain-table">
                <tbody>
                    <tr>
                        <td colSpan="2" className="TWbheader TWtextCenter">ПРОТОКОЛ ОСМОТРА ДЕЖУРНЫМ ВРАЧОМ ПРИ ПРИЁМЕ БОЛЬНОГО</td>
                    </tr>
                    <tr>
                        <td className="TWbborder-flex TWnoborder">
                        </td>
                    </tr>
                    <tr>
                        <td className="TWhalf2">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Осмотрен дежурным врачом</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "58mm" }}>час</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "74mm" }}>мин</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "82mm" }}>"</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "91mm" }}>"</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "109mm" }}>20</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "120mm" }}>г.</div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>Жалобы</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </td>




                        <td className="TWbright2">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>Санобработка:</b> ванна, душ,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">упрощенная</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>Доставка в отделение:</b> носилки,</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle">пешком с сопровождающим</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </td>




                    </tr>
                    <tr>
                        <td className="TWhalf" colSpan="2">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle"><b>Первые проявления настоящего увечья (ранения, травмы, контузии) или заболевания с
                                                указанием обстоятельств,</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle"><b>времени, места его получения и предшествующего лечения</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                        </td>
                                    </tr>


                                </tbody>
                            </table>

                        </td>


                    </tr>

                    <tr>
                        <td colSpan="2">

                            <table className="TWinner-table">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "66%", "height": "10mm" }}>

                                            <table className="TWblock-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="TWbborder-flex TWtable-no-border" style={{
                                                            borderWidth: "0 !important",
                                                            "height": "3mm",
                                                            "padding": "0",
                                                            "borderTop": "none !important",
                                                            "borderLeft": "none !important",
                                                        }}>
                                                            <div className="TWbtitle"><b>Данные объективного исследования.</b></div>
                                                            <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>Температура</div>
                                                            <div className="TWbtitle" style={{ "marginLeft": "101mm" }}>Пульс</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="TWbborder-flex TWtable-no-border" style={{ "height": "3mm", "padding": "0", "borderTop": "none !important", "borderLeft": "none !important", "borderRight": "none !important" }}>
                                                            <div className="TWbtitle">Артериальное давление</div>
                                                            <div className="TWbtitle" style={{ "marginLeft": "64mm" }}>мм рт. ст.</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                        </td>
                                        <td>
                                            <div className=""><b>Диагностические, лечебные</b></div>
                                            <div className=""><b>назначения, особые указания по</b></div>
                                            <div className=""><b>наблюдению</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><b>Режим - </b></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><b>Диета -</b></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Диагноз:</b></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>



                    <tr>
                        <td colSpan="2">

                            <table className="TWblock-table">
                                <tbody>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder"></td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder"></td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder"></td>
                                    </tr>
                                    <tr>
                                        <td className="TWbborder-flex TWnoborder">
                                            <div className="TWbtitle-big">Эвакуационная характеристика:</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWbig"></td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWbig"></td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWbig"></td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWbig"></td>
                                    </tr>

                                    <tr>
                                        <td className="TWbborder-flex TWbig TWnoborder"></td>
                                    </tr>


                                    <tr>
                                        <td className="TWbborder-flex">
                                            <div className="TWbtitle" style={{ "fontSize": "12pt" }}><b>Врач:</b></div>
                                            <div className="TWbtitle" style={{ "marginLeft": "92mm" }}>"</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "101mm" }}>"</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "136mm" }}>20</div>
                                            <div className="TWbtitle" style={{ "marginLeft": "150mm", "paddingRight": "44mm" }}>г.</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </>;
}

export const PrintTpl_5 = memo(PrintTpl_5Component);
