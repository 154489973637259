import React, { memo, useState, useMemo, useEffect, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router';

import {
    Divider,
    Typography,
} from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { urlHistory } from '../../../../common/helper/UrlRouter';

import {
    selectPatientData,
    setCurrentHistoryItem
} from "../../historySlice";

import { OtherDiseases } from "../OtherDiseases";
import { HistoryItem } from "../HistoryItem";
import { PatientInfo } from "../PatientInfo";


const PatientComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    // const [value, setValue] = useState(currentValue || null);

    const patientData = useSelector(selectPatientData);

    const {
        id,
        suid,
        fio,
        birthday,
        militaryUnitCode,
        militaryUnit,
        militaryFunction,
        militaryRank,
        events
    } = patientData;


    const [currentEvent, setCurrentEvent] = useState({});
    const [isLast, setIsLast] = useState(false);

    const handleChangeCurrentEvent = (event = {}) => {
        setCurrentEvent(event);
        navigate(`${urlHistory}/${params.patientId}/${event.id}`);
    }

    const evacuations = useMemo(() => currentEvent.history ? [...currentEvent.history] : [], [currentEvent.history]);

    let unknownLocationIndex = null;
    for (let i = 0; i < evacuations.length; i++) {
        if (evacuations[i + 1]) {
            // Приехал не туда, куда его отправили, значит по пути он был где-то ещё. Добавляем точку.
            if (evacuations[i].evacuationLocation !== evacuations[i + 1].location) {
                unknownLocationIndex = i + 1;
            }
        }
    }

    // заведём новую переменную, чтобы не мутировать evacuations
    let evacuationsList = useCallback(() => [], []);

    if (unknownLocationIndex) {
        let unknownLocation = {
            dischargeDate: null,
            disease: "",
            evacuationLocation: "",
            helping: "",
            hospitalizationDate: "",
            id: "UNKNOWN_" + unknownLocationIndex,
            location: "промежуточные этапы",
            status: "",
            color: "#ececec",
            type: "UNKNOWN",
        };

        evacuationsList = evacuations.toSpliced(unknownLocationIndex, 0, unknownLocation);
        // evacuations.splice(unknownLocationIndex, 0, unknownLocation);
    } else {
        evacuationsList = [...evacuations];
    }

    // сортировка по дате госпитализации
    // console.log(evacuations);
    // evacuations.sort(function (a, b) {
    //     return b.hospitalizationDate < a.hospitalizationDate;
    // });
    // console.log(evacuations);

    // evacuations.reverse();


    let patientLastRowInfo = [];

    if (militaryUnit) {
        patientLastRowInfo.push(militaryUnit);
    }
    if (militaryFunction) {
        patientLastRowInfo.push(militaryFunction);
    }
    if (militaryRank) {
        patientLastRowInfo.push(militaryRank);
    }



    const handleEventClick = useCallback((historyId) => {
        navigate(`${urlHistory}/${params.patientId}/${params.eventId}/${historyId}`);

    }, [navigate, params.eventId, params.patientId]);

    const handleOpenHistoryItem = useCallback((historyId) => {
        let evacuationIndex = evacuationsList.findIndex(item => item.id === historyId);
        evacuationIndex = evacuationIndex > -1 ? evacuationIndex : null;

        const evacuation = evacuationIndex !== null ? evacuationsList[evacuationIndex] || {} : {};

        if (evacuation.type === "UNKNOWN") {
            return;
        }

        setIsLast(evacuationIndex === 0);
        dispatch(setCurrentHistoryItem(evacuation));

    }, [dispatch, evacuationsList]);

    const lastEvacuation = evacuations[0] && evacuations[0].evacuationLocation ? evacuations[0] : {};

    let notEndedEvacuation = {};

    if (lastEvacuation.evacuationLocation) {
        if (lastEvacuation.evacuationLocation.toLowerCase() !== "в строй") {
            notEndedEvacuation = {
                dischargeDate: null,
                disease: "",
                evacuationLocation: "",
                helping: "",
                hospitalizationDate: "",
                id: lastEvacuation.id + "NOT_ENDED",
                location: lastEvacuation.evacuationLocation,
                status: "в пути",
                color: "#cc2121",
            };
        } else {
            notEndedEvacuation = {
                dischargeDate: null,
                disease: "",
                evacuationLocation: "",
                helping: "",
                hospitalizationDate: "",
                id: lastEvacuation.id + "NOT_ENDED",
                location: lastEvacuation.evacuationLocation,
                status: "",
                color: "green",
            };
        }
    }

    useEffect(() => {
        if (params.historyId) {
            handleOpenHistoryItem(params.historyId);
        }
    }, [dispatch, evacuations, handleOpenHistoryItem, params.historyId]);

    useEffect(() => {
        let event = (events && events.find(event => event.id === params.eventId)) || {};
        setCurrentEvent(event);

    }, [events, params.eventId]);


    return <>
        <PatientInfo />
        <Divider />
        {/* {
              dateOfVisits: "23.06.2024",
              diagnosis: "Осколочное ранение правой ноги",
              locationWhere: "Госпиталь #1"
            }, */}
        <><Timeline>


            {notEndedEvacuation.location ? <TimelineItem>
                <TimelineOppositeContent sx={{ m: '3px 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary">
                    {/* {evacuation.hospitalizationDate} */}{notEndedEvacuation.status}
                    {/* {evacuation.hospitalizationDate ? new Date(evacuation.hospitalizationDate).toLocaleString() : "-"} */}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot
                        sx={{
                            // height: 100,
                            backgroundColor: notEndedEvacuation.color,
                            opacity: 0.8,
                        }} />
                    <TimelineConnector
                        sx={{
                            height: 100,
                            opacity: 0.8,
                            background: notEndedEvacuation.color === "#cc2121" ? `linear-gradient(to bottom, ${notEndedEvacuation.color} 50%, #FFF 50% )` : "inherit",
                            backgroundSize: "100% 10px",
                            backgroundColor: notEndedEvacuation.color,
                            "&::after": {
                                content: '""',
                                width: 0,
                                height: 0,
                                borderLeft: "6px solid transparent",
                                borderRight: "6px solid transparent",
                                borderBottom: `13px solid ${notEndedEvacuation.color}`,
                                margin: "-2px 0px 0px -5px",
                                padding: "6px 0px -14px 0",
                                position: "absolute",
                            }
                        }} />
                </TimelineSeparator>
                <TimelineContent sx={{ mt: '-5px' }}>
                    <Typography variant="h6" component="span">
                        {notEndedEvacuation.location}
                    </Typography>
                    {/* <Typography> {evacuation.disease}</Typography> */}
                </TimelineContent>
            </TimelineItem> : null}



            {evacuationsList.map((evacuation, index) => {

                // Не очень хорошая практика, но это самое удобное место на данный момент.
                if (params.patientId && index === 0) {
                    // dispatch(setCurrentHistoryItem(evacuation));
                }

                return <Fragment key={index}>

                    <TimelineItem
                        onClick={evacuation.type === "UNKNOWN" ? null : () => handleEventClick(evacuation.id)}
                        sx={{
                            cursor: evacuation.type === "UNKNOWN" ? "default" : "pointer"
                        }}
                    >
                        <TimelineOppositeContent sx={{ m: '3px 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary">
                            {/* {evacuation.hospitalizationDate} */}
                            {evacuation.hospitalizationDate ? new Date(evacuation.hospitalizationDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : ""}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot
                                sx={{
                                    // height: 100,
                                    backgroundColor: evacuation.color || "",
                                    border: "2px solid #bdbdbd",
                                    opacity: 0.8,
                                }} />
                            <TimelineConnector
                                sx={{
                                    height: 100,
                                    "&::after": {
                                        content: '""',
                                        width: 0,
                                        height: 0,
                                        borderLeft: "6px solid transparent",
                                        borderRight: "6px solid transparent",
                                        borderBottom: "13px solid #bdbdbd",
                                        margin: "-2px 0px 0px -5px",
                                        padding: "6px 0px -14px 0",
                                        position: "absolute",
                                    }
                                }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ mt: '-5px' }}>
                            <Typography
                                sx={{
                                    opacity: !(evacuation.location && evacuation.location !== "-") || evacuation.type === "UNKNOWN" ? 0.5 : 1,
                                }}
                                variant="h6"
                                component="span"
                            >
                                {evacuation.location && evacuation.location !== "-" ? evacuation.location : "в процессе заполнения"}
                            </Typography>
                            <Typography> {evacuation.disease}</Typography>
                        </TimelineContent>
                    </TimelineItem>

                </Fragment>
            }
            )}

            {currentEvent.eventDate || currentEvent.location ? <TimelineItem
                // onClick={() => handleEventClick(evacuation)}
                sx={{
                    // cursor: "pointer"
                }}
            >
                <TimelineOppositeContent sx={{ m: '3px 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary">
                    {/* {evacuation.hospitalizationDate} */}
                    Дата ранения: {currentEvent.eventDate ? new Date(currentEvent.eventDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-"}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    {/* <TimelineConnector sx={{ height: 100 }} /> */}
                </TimelineSeparator>
                <TimelineContent sx={{ mt: '-5px' }}>
                    <Typography variant="h6" component="span">
                        {currentEvent.location}
                    </Typography>
                    <Typography><b>Обстоятельства:</b> {currentEvent.circumstances || " - "}</Typography>
                    <Typography><b>Характер:</b> {currentEvent.nature || " - "}</Typography>
                    <Typography><b>Степень:</b> {currentEvent.gravityOfInjury || " - "}</Typography>
                </TimelineContent>
            </TimelineItem> : null
            }

        </Timeline>
        </>

        <OtherDiseases
            currentEvent={currentEvent}
            onChangeCurrentEvent={handleChangeCurrentEvent}
        />
        <HistoryItem
            currentEvent={currentEvent}
            notEnded={!!notEndedEvacuation.location}
            isLast={isLast}
        />
    </>;
}

export const Patient = memo(PatientComponent);
