import React, { useState, useEffect } from 'react';
import { fields, buttons, dialogs, cards } from '../app/thema';
import { GetUsersAsync } from '../common/api/Management';

import { Grid, Card, Typography } from '@mui/material';

import { UserCard, MilitaryOrganizationsCard } from '../components/Cards';


/**
 * 
 * @returns 
 */
export default function FormaByUsers() {
    const [userCard, setUserCard] = useState([]);

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Медицинские организации',
        },
        cardItem: {
            thema: cards.collection1,
            fontSize: '16pt',
            img: '../img/MilitaryOrganizations.jpg',
            title: 'Мишин А.В.',
            href: ''
        },
        items: [
            {
                key: 1,
                title: 'ВПСГ "442 ВКГ" МО РФ',
                fullName: 'ВПСГ "442 ВКГ" МО РФ',
            },
            {
                key: 2,
                title: 'Медрота 41 МСП (К)',
                fullName: 'Медрота 41 МСП (К)',
            },
            {
                key: 3,
                title: 'Медрота 41 МСП (М)',
                fullName: 'Медрота 41 МСП (М)',
            },
            {
                key: 4,
                title: 'Медрота 41 МСП (Т)',
                fullName: 'Медрота 41 МСП (Т)',
            }
        ]
    }

    // Загрузка данных
    const handleDataLoader = async () => {
        let res = await GetUsersAsync();
        let users = [];
        res.users.map((elem) => {
            users.push({
                key: elem.id,
                login: elem.login,
                lic: elem.key,
                isBlock: elem.blocked,
            });
        });
        setUserCard(users);
    }

    // Формирование карточки пользователя
    const CardItem = (props) => {
        return (
            <Grid
                item xs={12} sm={6} md={4} lg={3} xl={2}
            >
                <MilitaryOrganizationsCard
                    thema={model.cardItem.thema} fontSize={model.cardItem.fontSize} img={model.cardItem.img}
                    title={props.title} fullName={props.fullName}
                />
            </Grid>
        )
    }

    useEffect(() => {
        handleDataLoader();
    }, []);

    return (
        <Grid
            sx={{
                mt: '5px',
                px: '10px',
            }}
            spacing={2}
            container
        >
            <Grid
                item xs={12} sm={12} md={12} lg={12} xl={12}
            >
                <Card
                    sx={{
                        py: '15px',
                        borderRadius: '10px'
                    }}
                    variant="outlined"
                >                    
                    <Grid
                        style={{
                            textAlign: 'center',
                        }}
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <Typography
                            variant="h4" color={model.header.thema.borderColor}
                        >
                            {model.header.label}
                        </Typography>
                    </Grid>
                </Card>
            </Grid>
            {model.items.map((elem) => {
                return (
                    <CardItem key={elem.key} title={elem.title} fullName={elem.fullName} />
                )
            })}
        </Grid>
    )
}
