import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { fields, buttons, cards } from '../app/thema';
import { GetUsersAsync } from '../common/api/Management';

import {
    Grid, Card, Typography
} from '@mui/material';
import { UserCard } from '../components/Cards';
import { UserEditDlg } from '../components/Dialogs';
import { TextFld, PasswordFld } from '../components/Fields';
import { StartIconBtn, EndIconBtn } from '../components/Buttons';

import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';


/**
 * 
 * @returns 
 */
export default function FormaByUsers() {
    const [userCard, setUserCard] = useState([]);
    const [open, setOpen] = React.useState(false);

    // Загрузка данных
    const handleDataLoader = async () => {
        let res = await GetUsersAsync();
        let users = [];
        res.users.map((elem) => {
            users.push({
                key: elem.id,
                title: elem.fio,
                login: elem.login,
                lic: elem.key,
                isBlock: elem.blocked,
            });
        });
        setUserCard(users);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleFormAction = (values) => {
    };

    useEffect(() => {
        handleDataLoader();
    }, []);

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Пользователи системы',
        },
        cardItem: {
            thema: cards.collection1,
            fontSize: '16pt',
            img: '../img/MilitaryUser.jpg',
            title: 'Мишин А.В.',
            href: ''
        },
        items: [
            {
                key: 1,
                title: 'Мишин А.В.',
                login: 'ritm@bk.ru',
                lic: 'qwerty123',
                isBlock: true,
            },
            {
                key: 2,
                title: 'Иванов Э.А.',
                login: 'ivanov@bk.ru',
                lic: 'qwerty123',
                isBlock: true,
            },
            {
                key: 3,
                title: 'Тест',
                login: 'ivanov@bk.ru',
                lic: 'qwerty123',
                isBlock: false,
            },
            {
                key: 4,
                title: 'Тест',
                login: 'ivanov@bk.ru',
                lic: 'qwerty123',
                isBlock: false,
            },
            {
                key: 5,
                title: 'Тест',
                login: 'ivanov@bk.ru',
                lic: 'qwerty123',
                isBlock: false,
            },
            {
                key: 6,
                title: 'Тест',
                login: 'ivanov@bk.ru',
                lic: 'qwerty123',
                isBlock: false,
            }
        ],
        dialogEdit: {
            title: 'Редактирование пользователя',
            fio: {
                thema: fields.collection2,
                size: 'small',
                type: 'text',
                variant: 'outlined',
                name: 'fio',
                label: 'Фамилия Имя Отчество',
            },
            mRankS:{
                thema: fields.collection2,
                size: 'small',
                type: 'text',
                variant: 'outlined',
                name: 'mRankS',
                label: 'Звание',
            },
            login: {
                thema: fields.collection2,
                size: 'small',
                type: 'text',
                variant: 'outlined',
                name: 'login',
                label: 'Логин',
            },
            password: {
                thema: fields.collection2,
                size: 'small',
                type: 'text',
                variant: 'outlined',
                name: 'password',
                label: 'Пароль',
            },
            lic: {
                thema: fields.collection2,
                size: 'small',
                type: 'text',
                variant: 'outlined',
                name: 'key',
                label: 'Ключ',
            },
            bntCancel: {
                thema: buttons.collection1,
                icon: <UndoOutlinedIcon />,
                variant: 'contained',
                handleClick: handleClose,
                value: 'Отменить',
            },
            btnAccept: {
                thema: buttons.collection1,
                icon: <SaveAsOutlinedIcon />,
                variant: 'contained',
                handleClick: handleClose,
                value: 'Обновить',
            }

        }
    }
    const context = {
        fio: '',
        mRankS: '',
        login: '',
        password: '',
        lic: '',
    }
    const validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    // Формирование карточки пользователя
    const CardItem = (props) => {
        return (
            <Grid
                item xs={12} sm={6} md={4} lg={3} xl={3}
            >
                <UserCard
                    thema={model.cardItem.thema} fontSize={model.cardItem.fontSize} img={model.cardItem.img}
                    title={props.title} href={model.cardItem.href} login={props.login} lic={props.lic}
                    isBlock={props.isBlock} handleEditClick={handleClickOpen}
                />
            </Grid>
        )
    }
    const DialogModal = (props) => {
        return (
            <formik>
                <UserEditDlg 
                    formik={props.formik} open={props.open} handleClose={props.handleClose} 
                    handleClickOpen={props.handleClickOpen}
                />
            </formik>
        )
    }
    return (
        <>
            <Grid
                sx={{
                    mt: '5px',
                    px: '10px',
                }}
                spacing={2}
                container
            >
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <Card
                        sx={{
                            py: '15px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            style={{
                                textAlign: 'center',
                            }}
                            item xs={12} sm={12} md={12} lg={12} xl={12}
                        >
                            <Typography
                                variant="h4" color={model.header.thema.borderColor}
                            >
                                {model.header.label}
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
                {userCard.map((elem) => {
                    return (
                        <CardItem
                            key={elem.key} title={elem.title} login={elem.login} lic={elem.lic} isBlock={elem.isBlock}
                            formik={formik}
                        />
                    )
                })}
            </Grid>
            <DialogModal 
                formik={formik} open={open} handleClose={handleClose} handleClickOpen={handleClickOpen}
            />
        </>
    )
}