import React, { memo, useLayoutEffect, useState, useCallback } from "react";
import {
    useGridApiContext,
} from '@mui/x-data-grid-pro';

import {
    Paper,
    InputBase,
} from "@mui/material";


const EditTextareaComponent = (props) => {
    const { id, field, value, colDef, hasFocus } = props;
    const [valueState, setValueState] = useState(value);
    const [inputRef, setInputRef] = useState(null);
    const apiRef = useGridApiContext();

    useLayoutEffect(() => {
        if (hasFocus && inputRef) {
            inputRef.focus();
        }
    }, [hasFocus, inputRef]);

    const handleChange = useCallback(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue(
                { id, field, value: newValue, debounceMs: 200 },
                event,
            );
        },
        [apiRef, field, id],
    );

    const width = colDef.computedWidth;

    return (
        <Paper elevation={1} sx={{ p: 0, minWidth: width }}>
            <InputBase
                multiline
                rows={4}
                value={valueState}
                sx={{
                    textarea: {
                        resize: 'vertical'
                    },
                    p: 0,
                    width: '100%',
                    // minHeight: "100px",
                    fontSize: "0.875rem"
                }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
            />
        </Paper>
    );
}

export const EditTextarea = memo(EditTextareaComponent);
