// REST API Контроллер взаимодействия с биллингом
import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
    IsCode200
} from './QueryHandler';
import {
    GetUsersPOST, GetUserEditPOST
} from '../helper/UrlController';
import {
    ResponseAlert, ResponseUserCheck,
} from '../models/Response';
import {
    RequestUsers,
} from '../models/Request';
import { 
    appStorage 
} from "../../helpers";


let authentication = appStorage.getItem('authentication') || {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}


/**
 * API для получения списка пользователей
 * @returns 
 */
async function GetUsersAsync() {
    let model = {
        alert: {},
        users: []
    };
    try {
        let req = new RequestUsers().getModel();
        let res = await PostFetchAsync(GetUsersPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.users = res.users;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


export {
    GetUsersAsync,
}