import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
} from '@mui/material';

import "./styles.css";
import dayjs from "dayjs";


const PrintTpl_7Component = ({ printData }) => {


    console.log(printData);

    let { patientData, currentHistoryItem, currentEvent, modalData = {} } = printData;


    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };


    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString(undefined, { dateStyle: "short", }) : "-",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    // let currentDate = new Date().toLocaleString(undefined, { dateStyle: "short", });

    let currentDate = `__.__.20__`;

    let mhNumber = currentHistoryItem.localNumber ? `_____/${currentHistoryItem.localNumber}` : "_____/_____";


    const Sign = () => {
        return <>
            <div className="b3-sign">
                Врио начальника военно-полевого сортировочного госпиталя (на 450 коек)<br />
                ФГКУ «442 ВКГ» Минобороны России<br />
                <div className="b3-sign-2">
                    подполковник медицинской службы
                </div>
            </div>
            <div className="b3-sign-name">
                А. Белов
            </div>
            <div className="b3-sign-mp">
                М.П.
            </div>
        </>;
    };



    const birthday = patientData.birthday || "_________";
    const militaryUnitCode = patientData.militaryUnitCode || "_________";
    const militaryRank = patientData.militaryRank || "_________";
    const eventDate = currentEvent.eventDate ? new Date(currentEvent.eventDate).toLocaleString(undefined, { dateStyle: "short" }) : "_________";
    let hospitalizationDate = currentHistoryItem.hospitalizationDate || "_________";


    return <>

        <div className="page print">
            <div className="page-container">
                <style type="text/css" media="print">
                    {" @page { size: portrait; } "}
                </style>

                <div className="b1">
                    <img src="/img/mo-gerb.png" alt="" width="110" />
                    <div className="b1-text">
                        МИНИСТЕРСТВО ОБОРОНЫ<br />
                        РОССИЙСКОЙ ФЕДЕРАЦИИ<br />
                        Военно-полевой сортировочный<br />
                        госпиталь (на 450 коек)<br />
                        Федерального государственного<br />
                        казенного учреждения<br />
                        «442 военный клинический госпиталь»<br />
                        Минобороны России<br />
                        <div className="b1-bottom">№&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;от {currentDate} г.</div>
                    </div>
                </div>

                <div className="b2">
                    <div className="b2-text">
                        Приложение №2<br />
                        к приказу начальника военно-полевого<br />
                        сортировочного госпиталя (на 450 коек)<br />
                        ФГКУ «442 ВКГ» Минобороны России<br />
                        От 28 мая 2024 г. № 71дсп<br /><br />
                        Приложение №2 к п.3 Инструкции
                    </div>
                </div>

                <div className="b3">
                    <div className="b3-title-1">
                        СПРАВКА
                    </div>
                    <div className="b3-title-2">
                        о ранении (контузии, травме, увечье) № {mhNumber}
                    </div>
                    <div className="b3-text">
                        I. Сообщаю, что {militaryRank} {patientData.fio}, войсковая часть {militaryUnitCode}, {birthday} г.р.,
                        проходящий военную службу в Вооруженных Силах Российской Федерации, получил ранение (контузию, травму, увечье)
                        «{currentHistoryItem.diagnosisFinal || currentHistoryItem.diagnosisClinical || currentHistoryItem.disease} от {eventDate} года»,
                        в связи с чем поступил на лечение {hospitalizationDate} года в военно-полевой сортировочный госпиталь (на 450 коек) ФГКУ «442 ВКГ» Минобороны России.
                    </div>
                    <Sign />
                </div>

            </div>
        </div>



        <div className="page print">
            <div className="page-container">

                <div className="b4">

                    <div className="b4-text">
                        II. Со справкой ознакомлен. Ранение (контузия, травма, увечье) мною получено(а) в ходе специальной военной операции. Об ответственности за предоставление ложной информации предупрежден:
                    </div>

                    <div className="b4-text-line-1">
                        (подпись, инициал имени, фамилия, дата)
                    </div>

                    <div className="b4-text-line-2">
                        (номер телефона)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(полный почтовый адрес для связи с военнослужащим)
                    </div>

                    <Sign />
                </div>



            </div>
        </div>
    </>;
}

export const PrintTpl_7 = memo(PrintTpl_7Component);
