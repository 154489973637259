import { POST, appStorage } from "../../helpers";

import { alertError, alertWarn } from '../../common/helper/ToastAlert';
// import { saveAs } from 'file-saver';
// import iconv from 'iconv-lite';
// var iconv = require('iconv-lite');

let authentication = appStorage.getItem('authentication') || {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}


/**
 * Валидация ответов от BeckEnd
 * @param {Object} res 
 * @returns true если запрос вернулся успешным, инаяе false
 */
function ResponseCheck(res) {

    // на случай если alert = null
    res.alert = res.alert || {};
    // Проверка статуса запроса
    if (res.alert === undefined && res.status !== undefined && res.status !== 200) {
        return false;
    }

    // Валидация
    if (res.alert === undefined || res.alert.code === undefined) {
        return false;
    }

    // Валидация
    if (res.alert.code != 200) {
        return false;
    }

    return true;
}

/**
 * Валидация кодов ответа от BackEnd
 * @param { object } res - Модель ответа
 * @returns true если запрос вернулся успешным, инаяе false
 */
function IsCode503(res) {
    // Валидация
    if (res.alert === undefined && res.result === undefined) {
        return true;
    }

    return false;
}

/**
 * Валидация кодов ответа от BackEnd
 * @param { object } res - Модель ответа
 * @returns true если запрос вернулся успешным, инаяе false
 */
function IsCode200(res) {
    console.log(res);
    if (res.alert?.code === '200') {
        return true
    }

    return false;
}


/**
 * GET Взаимодействия с REST API контрроллерами сервиса
 * @param { string } url адрес API контроллера сервиса
 * @returns ответ от API
 */
async function GetFetchAsync(url) {
    let result = {};
    try {
        let res = await fetch(`${url}?key=${authParams.key}&authkey=${authParams.authkey}`);
        result = await res.json();
    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * POST Взаимодействия с REST API контрроллерами сервиса
 * @param { String } url адрес API контроллера сервиса
 * @param { Object } req запрос к API
 * @returns ответ от API
 */
async function PostFetchAsync(url, req) {
    let result = {};
    try {
        let res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                ...authParams,
                ...req,
            }),
        });
        result = await res.json();

        if (result && result.alert && result.alert.code === "401") {
            appStorage.removeItem('authentication');
            alertWarn('Неправильный ключ');

            setTimeout(() => {
                window.location = "/";
            }, 1000);
        }

    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * POST Взаимодействия с REST API контрроллерами сервиса для скачивания файла
 * @param { String } url адрес API контроллера сервиса
 * @param { Object } req запрос к API
 * @returns ответ от API
 */
async function PostFetchFileAsync(url, req) {

    return POST({
        path: url,
        data: {
            ...req,
            // parseMethod
        },
        headers: { 'Content-Type': 'application/json;charset=windows-1251' },
        responseType: 'blob',
        success: data => {
            console.log(data);



            // let body = new Buffer(data, 'binary');
            // let conv = Iconv('windows-1251', 'utf8');
            // body = conv.convert(body).toString();

            // console.log(body);

            // var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
            // saveAs(blob, `${"allState.fileName"}_${"currentDateTime"}.csv`);


            let a = document.createElement("a");
            let file = new Blob([data], { type: 'text/plain;charset=windows-1251' });

            // const arrayBuffer = new TextEncoder().encode(data, "windows-1251");


            // let decoder = new TextDecoder("utf-8");
            // let text = decoder.decode(arrayBuffer);
            // console.log(text);
            // console.log(file);





            console.log(file);


            // const encoderUTF8 = new TextEncoder();
            // //Закодируем строку
            // const arrayUTF8 = encoderUTF8.encode(data);
            // //Теперь создадим декодер в cp1251, список кодировок и их метки здесь 
            // // https://developer.mozilla.org/en-US/docs/Web/API/Encoding_API/Encodings
            // let encToCP1251 = new TextDecoder('cp1251');
            // //Получаем строку в нужной кодировке
            // const strCP1251 = encToCP1251.decode(arrayUTF8);


            // console.log(strCP1251);




            a.href = URL.createObjectURL(file);
            a.download = "stat.csv";
            a.click();

        },
        fail: (data) => {
            console.log('fail', data);
        },
        always: (data) => {

        }
    });









    // // return;

    // let result;
    // try {
    //     let res = await fetch(url, {
    //         method: 'POST',
    //         headers: {
    //             // 'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(req),
    //     });
    //     result = await res.json();
    //     console.log(result);

    //     let a = document.createElement("a");
    //     let file = new Blob([result], {type: 'application/csv'});
    //     a.href = URL.createObjectURL(file);
    //     a.download = "stat.csv";
    //     a.click();




    // } catch (err) {
    //     console.log(err);
    // }

    // return result || {};
}

/**
 * PUT Взаимодействия с REST API контрроллерами сервиса
 * @param { String } url адрес API контроллера сервиса
 * @param { Object } req запрос к API
 * @returns ответ от API
 */
async function PutFetchAsync(url, req) {
    let result = {};
    try {
        let res = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(req),
        });
        result = await res.json();
    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * DELETE Взаимодействия с REST API контрроллерами сервиса
 * @param { String } url адрес API контроллера сервиса
 * @param { Object } req запрос к API
 * @returns ответ от API
 */
async function DeleteFetchAsync(url, req) {
    let result = {};
    try {
        let tmp = `${url}/${req.id}`;
        let res = await fetch(tmp, { method: 'DELETE' });
        result = await res.json();
    } catch (err) {
        console.log(err);
    }

    return result;
}


export {
    IsCode200, IsCode503, ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync, PostFetchFileAsync
}
