import React, { useEffect, useState } from 'react';
import { fields, buttons } from '../app/thema';
import { Grid } from '@mui/material';
import { TextFld, DateFld, PhoneFld } from '../components/Fields';
import { ComboBoxEdit } from './ComboBoxEdit';

/**
 * Виджет ввода полных сведений о военнослужащем
 * @param { Object } props ередаваемые параметры
 * @returns виджет
 */
const FullNameInput =(props) =>{
    const handleBirthdayChange = (e) => {
        props.formik.setFieldValue(model.birthday.name, e.target.value);
    }

    const model = {
        surname: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'surname',
            label: 'Фамилия',
        },
        name: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'name',
            label: 'Имя',
        },
        patronymic: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'patronymic',
            label: 'Отчество',
        },
        birthday: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'birthday',
            label: 'Дата рождения',
        },
        phoneNumber: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'phoneNumber',
            label: 'Контактный телефон',
            mask: '+7(999)999-99-99'
        },
        militaryUnit: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryUnitEdit',
                label: 'Воинская часть',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'militaryUnit',
                label: 'Воинская часть',
                variant: 'outlined',
                elems: props.militaryPartList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        division: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'divisionEdit',
                label: 'Подразделение',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'division',
                label: 'Подразделение',
                variant: 'outlined',
                elems: props.militaryUnitList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        rota: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'rotaEdit',
                label: 'Рота',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'rota',
                label: 'Рота',
                variant: 'outlined',
                elems: props.militaryCompanyList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        platoon: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'platoonEdit',
                label: 'Взвод',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'platoon',
                label: 'Взвод',
                variant: 'outlined',
                elems: props.militaryPlatoonList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        department: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'departmentEdit',
                label: 'Отделение',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'department',
                label: 'Отделение',
                variant: 'outlined',
                elems: props.militaryDepartmentList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        actualRank: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'actualRankEdit',
                label: 'Воинское звание',
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'actualRank',
                label: 'Воинское звание',
                variant: 'outlined',
                elems: props.militaryRankList,
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
    }
// useEffect(()=>{
//     props.formik.setFieldValue("platoon", props.formik.values.platoon);
// }, []);
    return (
        <>
            {props.canEditName ? <> <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <TextFld
                    thema={model.surname.thema} name={model.surname.name} size={model.surname.size}
                    type={model.surname.type} variant={model.surname.variant} label={model.surname.label}
                    value={props.formik.values.surname}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.surname} error={props.formik.errors.surname}
                />
            </Grid>
                <Grid
                    item xs={12} sm={12} md={12} lg={6} xl={6}
                >
                    <TextFld
                        thema={model.name.thema} name={model.name.name} size={model.name.size}
                        type={model.name.type} variant={model.name.variant} label={model.name.label}
                        value={props.formik.values.name}
                        handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                        touched={props.formik.touched.name} error={props.formik.errors.name}
                    />
                </Grid>
                <Grid
                    item xs={12} sm={12} md={12} lg={6} xl={6}
                >
                    <TextFld
                        thema={model.patronymic.thema} name={model.patronymic.name} size={model.patronymic.size}
                        type={model.patronymic.type} variant={model.patronymic.variant} label={model.patronymic.label}
                        value={props.formik.values.patronymic}
                        handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                        touched={props.formik.touched.patronymic} error={props.formik.errors.patronymic}
                    />
                </Grid>
            </> : <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            />}
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <DateFld
                    thema={model.birthday.thema} size={model.birthday.size} type={model.birthday.type}
                    variant={model.birthday.variant} name={model.birthday.name} label={model.birthday.label}
                    value={props.formik.values.birthday}
                    handleBlur={props.formik.handleBlur} handleChange={handleBirthdayChange}
                    touched={props.formik.touched.birthday} error={props.formik.errors.birthday}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={6} xl={6}
            >
                <PhoneFld
                    thema={model.phoneNumber.thema} size={model.phoneNumber.size} type={model.phoneNumber.type}
                    variant={model.phoneNumber.variant} name={model.phoneNumber.name} label={model.phoneNumber.label}
                    mask={model.phoneNumber.mask}
                    value={props.formik.values.phoneNumber}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange}
                    touched={props.formik.touched.phoneNumber} error={props.formik.errors.phoneNumber}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <ComboBoxEdit
                    model={model.militaryUnit} elems={model.militaryUnit.autoCompleteCbx.elems} value={props.formik.values.militaryUnit}
                    valueEdit={props.formik.values.militaryUnitEdit} val={props.militaryPart} isEdit={props.isEditMilitaryPath}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryPart} handleClick={props.handleClickMilitaryPath}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleChangeEdit={props.formik.handleChange}
                    touched={props.formik.touched.militaryUnit} touchedEdit={props.formik.touched.militaryUnitEdit}
                    error={props.formik.errors.militaryUnit} errorEdit={props.formik.errors.militaryUnitEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <ComboBoxEdit
                    model={model.division} elems={model.division.autoCompleteCbx.elems} value={props.formik.values.division}
                    valueEdit={props.formik.values.divisionEdit} val={props.militaryUnit} isEdit={props.isEditMilitaryUnit}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryUnit} handleClick={props.handleClickMilitaryUnit}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleChangeEdit={props.formik.handleChange}
                    touched={props.formik.touched.division} touchedEdit={props.formik.touched.divisionEdit}
                    error={props.formik.errors.division} errorEdit={props.formik.errors.divisionEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <ComboBoxEdit
                    model={model.rota} elems={model.rota.autoCompleteCbx.elems} value={props.formik.values.rota}
                    valueEdit={props.formik.values.rotaEdit} val={props.militaryCompany} isEdit={props.isEditMilitaryCompany}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryCompany} handleClick={props.handleClickMilitaryCompany}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleChangeEdit={props.formik.handleChange}
                    touched={props.formik.touched.rota} touchedEdit={props.formik.touched.rotaEdit}
                    error={props.formik.errors.rota} errorEdit={props.formik.errors.rotaEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <ComboBoxEdit
                    model={model.platoon} elems={model.platoon.autoCompleteCbx.elems} value={props.formik.values.platoon}
                    valueEdit={props.formik.values.platoonEdit} val={props.militaryPlatoon} isEdit={props.isEditMilitaryPlatoon}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryPlatoon} handleClick={props.handleClickMilitaryPlatoon}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleChangeEdit={props.formik.handleChange}
                    touched={props.formik.touched.platoon} touchedEdit={props.formik.touched.platoonEdit}
                    error={props.formik.errors.platoon} errorEdit={props.formik.errors.platoonEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <ComboBoxEdit
                    model={model.department} elems={model.department.autoCompleteCbx.elems} value={props.formik.values.department}
                    valueEdit={props.formik.values.departmentEdit} val={props.militaryDepartment} isEdit={props.isEditMilitaryDepartment}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryDepartment} handleClick={props.handleClickMilitaryDepartment}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleChangeEdit={props.formik.handleChange}
                    touched={props.formik.touched.department} touchedEdit={props.formik.touched.departmentEdit}
                    error={props.formik.errors.department} errorEdit={props.formik.errors.departmentEdit}
                />
            </Grid>
            <Grid
                item xs={12} sm={12} md={12} lg={3} xl={3}
            >
                <ComboBoxEdit
                    model={model.actualRank} elems={model.actualRank.autoCompleteCbx.elems} value={props.formik.values.actualRank}
                    valueEdit={props.formik.values.actualRankEdit} val={props.militaryRank} isEdit={props.isEditMilitaryRank}
                    setFieldValue={props.formik.setFieldValue} handleSetState={props.setMilitaryRank} handleClick={props.handleClickMilitaryRank}
                    handleBlur={props.formik.handleBlur} handleChange={props.formik.handleChange} handleChangeEdit={props.formik.handleChange}
                    touched={props.formik.touched.actualRank} touchedEdit={props.formik.touched.actualRankEdit}
                    error={props.formik.errors.actualRank} errorEdit={props.formik.errors.actualRankEdit}
                />
            </Grid>
        </>
    )
}


export {
    FullNameInput
}