import React from 'react';
import {
    Button, IconButton
} from '@mui/material';


const style = {
    my: 1,
    active: {
        boxShadow: 'none',
    }
}


/**
 * Компонент кнопка
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function Btn(props) {
    return (
        <Button
            sx={{
                my: style.my,
                backgroundColor: props.thema.backgroundColor,
                '&:hover': {
                    backgroundColor: props.thema.backgroundColor,
                },
                '&:active': {
                    boxShadow: style.active.boxShadow,
                    backgroundColor: props.thema.backgroundColor,
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth variant={props.variant}
        >
            {props.value}
        </Button>
    )
}

/**
 * Компонент кнопка ввиде киноки
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function IconBtn(props){
    return (
        <IconButton
            sx={{
                my: style.my,
                color: props.thema.backgroundColor,
            }}
            onClick={props.handleClick} disabled={props.isDisabled}
        >
            {props.icon}
        </IconButton>
    )
}

/**
 * Компонент кнопка иконка в начале
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function StartIconBtn(props) {
    return (
        <Button
            sx={{
                my: style.my,
                backgroundColor: props.thema.backgroundColor,                
                mt: '0px',
                mb: '0px',
                pt: '8px',
                pb: '8px',
                '&:hover': {
                    backgroundColor: props.thema.backgroundColor,
                },
                '&:active': {
                    boxShadow: style.active.boxShadow,
                    backgroundColor: props.thema.backgroundColor,
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth variant={props.variant}
            startIcon={props.icon} onClick={props.handleClick}
        >
            {props.value}
        </Button>
    )
}

/**
 * Компонент кнопка иконка в конце
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function EndIconBtn(props) {
    return (
        <Button
            sx={{
                my: style.my,
                backgroundColor: props.thema.backgroundColor,
                mt: '0px',
                mb: '0px',
                pt: '8px',
                pb: '8px',
                '&:hover': {
                    backgroundColor: props.thema.backgroundColor,
                },
                '&:active': {
                    boxShadow: style.active.boxShadow,
                    backgroundColor: props.thema.backgroundColor,
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth variant={props.variant}
            endIcon={props.icon} onClick={props.handleClick}
        >
            {props.value}
        </Button>
    )
}


/**
 * Компонент кнопка submit
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function SubmitBtn(props) {
    return (
        <Button
            sx={{
                my: style.my,
                backgroundColor: props.thema.backgroundColor,
                '&:hover': {
                    backgroundColor: props.thema.backgroundColor,
                },
                '&:active': {
                    boxShadow: style.active.boxShadow,
                    backgroundColor: props.thema.backgroundColor,
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth type='submit' variant={props.variant}
        >
            {props.value}
        </Button>
    )
}

/**
 * Компонент кнопка submit ввиде киноки
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function SubmitIconBtn(props){
    return (
        <IconButton
            sx={{
                my: style.my,
                color: props.thema.backgroundColor,                
                mt: '0px',
                mb: '0px',
                pt: '8px',
                pb: '8px',
            }}
            fullWidth type='submit' onClick={props.handleClick}
        >
            {props.icon}
        </IconButton>
    )
}

/**
 * Компонент кнопка submit иконка в начале
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function SubmitStartIconBtn(props){
    return (
        <Button
            sx={{
                my: style.my,
                backgroundColor: props.thema.backgroundColor,                
                mt: '0px',
                mb: '0px',
                pt: '8px',
                pb: '8px',
                '&:hover': {
                    backgroundColor: props.thema.backgroundColor,
                },
                '&:active': {
                    boxShadow: style.active.boxShadow,
                    backgroundColor: props.thema.backgroundColor,
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth type='submit' variant={props.variant}
            startIcon={props.icon}
        >
            {props.value}
        </Button>
    )
}

/**
 * Компонент кнопка submit иконка в конце
 * @param { Object } props 
 * @returns компонент
 */
function SubmitEndIconBtn(props) {
    return (
        <Button
            sx={{
                my: style.my,
                backgroundColor: props.thema.backgroundColor,                
                mt: '0px',
                mb: '0px',
                pt: '8px',
                pb: '8px',
                '&:hover': {
                    backgroundColor: props.thema.backgroundColor,
                },
                '&:active': {
                    boxShadow: style.my,
                    backgroundColor: props.thema.backgroundColor,
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth type='submit' variant={props.variant}
            endIcon={props.icon}
        >
            {props.value}
        </Button>
    )
}


export {
    Btn, IconBtn, StartIconBtn, EndIconBtn,
    SubmitBtn, SubmitIconBtn, SubmitStartIconBtn, SubmitEndIconBtn,
}