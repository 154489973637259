import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
    Typography,
    Stack,
    Box,
    CircularProgress,
} from '@mui/material';

import {
    selectPatientData,
} from "../../personnelSlice";

const ListItem = ({ name, value = "" }) => {
    return <Typography variant="body2" sx={{ margin: "0 !important" }} component={"div"}>
        <Box sx={{
            fontWeight: 700,
            display: "inline-flex",
            m: "4px",
        }}>{name}:</Box> {value}</Typography>;
}

const PatientInfoComponent = ({
    blockStyles = {},
    bottomComponent,
}) => {
    const patientData = useSelector(selectPatientData);

    const {
        suid,
        surName,
        name,
        patronymic,
        birthday,
        phoneNumber,
        militaryUnitCode,
        militaryRank,
        division,
        rota,
        platoon,
        militaryDep,
        contingent,
    } = patientData;

    return <Box>
        {suid ? <Stack
            spacing={2}
            sx={{
                textAlign: "center",
                m: 1,
                ...blockStyles,
            }} >
            <Typography variant="h6" sx={{ margin: "0 !important" }}>Личный номер №{suid}</Typography>
            <Typography variant="h5" sx={{ margin: "0 !important" }}>{`${surName} ${name} ${patronymic}`}</Typography>
            <ListItem
                name="Дата рождения"
                value={birthday ? new Date(birthday).toLocaleDateString() : ""}
            />
            <ListItem
                name="Контактный телефон"
                value={phoneNumber}
            />
            <ListItem
                name="Воинская часть"
                value={militaryUnitCode}
            />
            <ListItem
                name="Подразделение"
                value={division}
            />
            <ListItem
                name="Рота"
                value={rota}
            />
            <ListItem
                name="Взвод"
                value={platoon}
            />
            <ListItem
                name="Отделение"
                value={militaryDep}
            />
            <ListItem
                name="Воинское звание"
                value={militaryRank}
            />
            <ListItem
                name="Контингент"
                value={contingent}
            />

            {bottomComponent ? bottomComponent : null}
        </Stack> : <CircularProgress size={"30px"}/>}
    </Box>;
}

export const PatientInfo = memo(PatientInfoComponent);
