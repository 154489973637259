import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import { Evacuation } from '../features/evacuation';

export default function PEvacuation(props) {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            <Evacuation />
        </>
    )
}
