import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchSendSearch,
  fetchGetSymptomsFromText,
  fetchSendGetPatientData,
  fetchSendUpdate300,
  fetchSendUpdatePersonnel,
} from "./personnelAPI";

// import { appStorage } from "../../helpers";




const initialState = {
  currentPatientId: null,
  currentPersonnelItem: {},
  patientEdit: {
    disease: [],
    lifesAnamnez: [],
  },
  patientData: {
  },

  loading: {
    applicationInfo: false,
    symptoms: false,
    diagnostic: false,
    serviceInfo: false,
    serviceList: false,
    clarifications: false,
  }
};

export const sendSearch = createAsyncThunk(
  "personnel/sendSearch",
  async (props, { getState }) => {

    console.log(props);
    const { data, success } = props;
    // Symptoms,// = "Symptoms",
    // Allergy, //= "Allergy",
    // Disease, //= "Disease",
    // CoDisease,// = "CoDisease",
    // Localization,
    // Details,
    // PatientsName,
    // DoctorsName,
    // ClinicsName,

    const {
      text,
      active_language = "ru",
      type = "Symptoms",
      conceptId = null,
    } = data;

    // const { auth } = getState();

    // console.log(auth);
    const response = await fetchSendSearch({
      data: {
        // authKey: auth.authKey,
        text,
        active_language,
        type,
        conceptId,
      },
      success
    });

    return response;
  }
);


export const sendGetPatientData = createAsyncThunk(
  "personnel/sendGetPatientData",
  async (props = {}, { getState }) => {
    const {
      data,
      success = () => { }
    } = props;
    // const { auth, personnel } = getState();

    const response = await fetchSendGetPatientData({
      data: {
        ...data
        // authKey: auth.authKey,
        // guid: personnel.resultData?.guid || null
      },
      success
    });

    return response;
  }
);

export const sendUpdate300 = createAsyncThunk(
  "personnel/sendUpdate300",
  async (props = {}, { getState }) => {
    const {
      data,
      success = () => { },
    } = props;
    // const { auth, personnel } = getState();

    const response = await fetchSendUpdate300({
      data: {
        ...data
      },
      success
    });

    return response;
  }
);

export const sendUpdatePersonnel = createAsyncThunk(
  "personnel/sendUpdatePersonnel",
  async (props = {}, { getState }) => {
    const {
      data,
      success = () => { },
    } = props;
    // const { auth, personnel } = getState();

    const response = await fetchSendUpdatePersonnel({
      data: {
        ...data
      },
      success
    });

    return response;
  }
);


export const sendGetSymptomsFromText = createAsyncThunk(
  "personnel/sendGetSymptomsFromText",
  async (props, { getState }) => {

    const { data } = props;

    const {
      active_language = "ru",
      text = "",
      type = "",
    } = data;

    const { auth } = getState();
    const response = await fetchGetSymptomsFromText({
      data: {
        authKey: auth.authKey,
        text,
        type,
        active_language,
      },
    });

    return response;
  }
);


export const personnelSlice = createSlice({
  name: "personnel",
  initialState,
  reducers: {

    addToSymptomsList: (state, action) => {
      console.log("addToSymptomsList", action);
      state.symptomsList = [
        ...state.symptomsList,
        action.payload
      ];
    },

    setResultGraphId: (state, action) => {
      state.resultGraphId = action.payload;
    },

    setRASPage: (state, action) => {
      state.RAS.currentPage = action.payload;
    },

    setSensorValue: (state, action) => {
      const { id, value } = action.payload;

      // const sensors = state.RAS.questions[state.RAS.currentPage]?.sensors || {};
      // const sensorsKeys = Object.keys(sensors);
      // console.log(sensorsKeys);
      // const currentSensorKey = sensorsKeys.find(sensorsKey => sensors[sensorsKey]?.id === id);
      // const currentSensor = sensors[currentSensorKey] || {};
      // console.log(currentSensor);

      if (state.RAS.questions && state.RAS.questions[state.RAS.currentPage]?.sensors[id]) {
        state.RAS.questions[state.RAS.currentPage].sensors[id].valueResponse = value;
      }
    },

    setSetting: (state, action) => {
      const { name, value } = action.payload;
      state.settings[name] = value;

      // appStorage.setItem("settings", state.settings);
    },

    setSettings: (state, action) => {
      state.settings = action.payload;
    },

    setPatient: (state, action) => {
      state.patient = action.payload;
    },

    setPatientEdit: (state, action) => {
      state.patientEdit = action.payload;
    },

    resetSettings: (state) => {
      state.settings = initialState.settings;
      // appStorage.removeItem("settings");
    },
    resetPatient: (state) => {
      state.patient = initialState.patient;
    },
    resetPatientEdit: (state) => {
      state.patientEdit = initialState.patientEdit;
    },

    addToPatientLifesAnamnez: (state, action) => {
      console.log("addToPatientLifesAnamnez", action);
      state.patient.lifesAnamnez = [
        ...state.patient.lifesAnamnez,
        action.payload
      ];
    },
    updateCurrentPersonnelItem: (state, action) => {
      console.log("updateCurrentPersonnelItem", action);
      state.currentPersonnelItem = {
        ...state.currentPersonnelItem,
        ...action.payload
      };
    },
    updatePatientData: (state, action) => {
      // Генерируем значение поля fio, которое не редактируем отдельно
      let fioArray = [action.payload.surName || "", action.payload.name || "", action.payload.patronymic || ""];
      let fio = fioArray.join(" ");
      fio = fio.trim();

      state.patientData = {
        ...state.patientData,
        ...action.payload,
        fio
      };
    },

    addToPatientDisease: (state, action) => {
      console.log("addToPatientDisease", action);
      state.patient.disease = [
        ...state.patient.disease,
        action.payload
      ];
    },

    addToPatientEditDisease: (state, action) => {
      console.log("addToPatientEditDisease", action);
      state.patientEdit.disease = [
        ...state.patientEdit.disease,
        action.payload
      ];
    },

    addToPatientEditLifesAnamnez: (state, action) => {
      console.log("addToPatientEditLifesAnamnez", action);
      state.patientEdit.lifesAnamnez = [
        ...state.patientEdit.lifesAnamnez,
        action.payload
      ];
    },

    addToClarificationsList: (state, action) => {
      state.symptomsList = state.symptomsList.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const payloadValue = action.payload.value || { name: "", id: "NO_PAYLIAD_VALUE" };
          const { name, id } = payloadValue;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToUnitsList: (state, action) => {

      console.log(action.payload);
      const itemId = action.payload.item?.id || "NO_ID";

      if (state.unitsList[itemId]) {

        state.unitsList[itemId] = {
          ...state.unitsList[itemId],
          value: action.payload.value,
        };
      } else {
        state.unitsList[itemId] = {
          unit: {},
          value: action.payload.value,
        };
      }
    },

    addToUnitsListFromSearch: (state, action) => {

      console.log(action.payload);
      const itemId = action.payload.item?.id || "NO_ID";

      if (state.unitsList[itemId]) {

        state.unitsList[itemId] = {
          ...state.unitsList[itemId],
          unit: action.payload.value,
        };
      } else {
        state.unitsList[itemId] = {
          unit: action.payload.value,
          value: "",
        };
      }
    },

    addToPatientClarificationsList: (state, action) => {
      state.patient.disease = state.patient.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToPatientEditClarificationsList: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    addToPatientEditLifesAnamnezClarificationsList: (state, action) => {
      state.patientEdit.lifesAnamnez = state.patientEdit.lifesAnamnez.map(item => {

        if (action.payload.item?.id === item.id) {
          if (!item.clarifications) {
            item.clarifications = [];
          }

          const { name, id } = action.payload.value;

          // Добавляем, только если ещё нет в списке
          if (item.clarifications.findIndex(item => item.id === id) === -1) {
            const newValue = {
              name,
              id,
              type: action.payload.type,
              unitname: null,
              value: 0,
              unitId: null,
            };

            item.clarifications.push(newValue);
          }
        }

        return item;
      });
    },

    removeClarificationById: (state, action) => {
      state.symptomsList = state.symptomsList.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientClarificationById: (state, action) => {
      state.patient.disease = state.patient.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientEditClarificationById: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removePatientEditLifesAnamnezClarificationById: (state, action) => {
      state.patientEdit.lifesAnamnez = state.patientEdit.lifesAnamnez.map(item => {

        if (action.payload.item?.id === item.id) {
          if (item.clarifications?.length) {
            item.clarifications = item.clarifications.filter(clarifacation => clarifacation.id !== action.payload.clarificationId);
          }
        }
        return item;
      });
    },

    removeSymptomById: (state, action) => {
      state.symptomsList = state.symptomsList.filter(item => item.id !== action.payload);
    },

    removePatientDiseaseById: (state, action) => {
      state.patient.disease = state.patient.disease.filter(item => item.id !== action.payload);
    },

    removePatientEditDiseaseById: (state, action) => {
      state.patientEdit.disease = state.patientEdit.disease.filter(item => item.id !== action.payload);
    },

    removePatientEditLifesAnamnezById: (state, action) => {
      state.patientEdit.lifesAnamnez = state.patientEdit.lifesAnamnez.filter(item => item.id !== action.payload);
    },
    resetSymptomsList: (state) => {
      state.symptomsList = initialState.symptomsList;
    },
    setCurrentSymptom: (state, action) => {
      state.currentSymptom = action.payload;
    },
    setCurrentPersonnelItem: (state, action) => {
      state.currentPersonnelItem = action.payload;
    },
    setCurrentPatientLifesAnamnez: (state, action) => {
      state.currentPatientLifesAnamnez = action.payload;
    },
    setCurrentPatientDisease: (state, action) => {
      state.currentPatientDisease = action.payload;
    },
    setCurrentPatientId: (state, action) => {
      state.currentPatientId = action.payload;
    },
    resetCurrentSymptom: (state) => {
      state.currentSymptom = initialState.currentSymptom;
    },
    resetCurrentPatientDisease: (state) => {
      state.currentPatientDisease = initialState.currentPatientDisease;
    },
    resetCurrentPatientLifesAnamnez: (state) => {
      state.currentPatientLifesAnamnez = initialState.currentPatientLifesAnamnez;
    },

  },

  extraReducers: (builder) => {
    builder




      .addCase(sendGetSymptomsFromText.pending, (state) => {
        state.status = "loading";
        state.loading.diagnostic = true;
      })

      .addCase(sendGetSymptomsFromText.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.diagnostic = false;
        console.log(action);
        console.log(action.meta.arg.data.type);

        const type = action.meta.arg.data.type;

        let data = action.payload?.symptoms?.formalized || [];

        if (type === "Symptoms" || type === "Diagnostic") {
          state.symptomsList.push(...data);
        }

        if (type === "Disease") {
          state.patientEdit.disease.push(...data);
        }

        if (type === "LifesAnamnez") {
          state.patientEdit.lifesAnamnez.push(...data);
        }

      })

      .addCase(sendGetSymptomsFromText.rejected, (state, action) => {
        state.status = "idle";
        state.loading.diagnostic = false;
      })


      .addCase(sendUpdate300.pending, (state) => {
        state.status = "loading";
        state.loading.personnel = true;
      })

      .addCase(sendUpdate300.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.personnel = false;
      })

      .addCase(sendUpdate300.rejected, (state, action) => {
        state.status = "idle";
        state.loading.personnel = false;
      })



      .addCase(sendUpdatePersonnel.pending, (state) => {
        state.status = "loading";
        state.loading.personnel = true;
      })

      .addCase(sendUpdatePersonnel.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.personnel = false;
      })

      .addCase(sendUpdatePersonnel.rejected, (state, action) => {
        state.status = "idle";
        state.loading.personnel = false;
      })





      .addCase(sendGetPatientData.pending, (state) => {
        state.status = "loading";
        state.loading.patientData = true;
      })

      .addCase(sendGetPatientData.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.patientData = false;

        let responseData = action.payload?.personal || {};

        console.log(responseData);

        //   test: "fff"
        // };

        /**
         * Конструктор
         * @param { String } idPersonnel УИН записи журнала личного состава
         * @param { Date } dateOfInjury Дата и время ранения
         * @param { String } locationWhereInjury Где получил ранение
         * @param { String } circumstances Обстоятельства ранения
         * @param { String } nature Характер ранения
         * @param { String } gravityOfInjury Тяжесть ранения
         * @param { Date } dateOfVisits Дата и время поступления/обращения
         * @param { String } diagnosis Диагноз
         * @param { String } helpProvided Оказана помощь
         * @param { String } locationMedicalAssistance Место оказания медицинской помщи
         * @param { String } recommendations Рекомендации при выписке
         * @param { String } locationWhere Куда эвакуирован
         * @param { Date } dateOfEvacuation Дата и время эвакуации
         
         */
        // let data = {
        //   patientInfo: {
        //     id: responseData.id,
        //     tokenNumber: responseData.suid || "",
        //     dataBirth: responseData.birthday || "",
        //     fio: responseData.fio || "",
        //     dateOfInjury: "DEL - 20.06.2024",
        //     locationWhereInjury: "DEL - г. Белград, село Новые Старики",
        //     nature: "DEL - осколочное ранение",
        //     division: responseData.militaryUnit || "",
        //     militaryRank: responseData.militaryRank || "",
        //     doljnost: responseData.militaryFunction || "",
        //   },

        //   evacuations: [
        //     {
        //       dateOfVisits: "23.06.2024",
        //       diagnosis: "Осколочное ранение правой ноги",
        //       locationWhere: "Госпиталь #1"
        //     },
        //     {
        //       dateOfVisits: "22.06.2024",
        //       diagnosis: "Осколочное ранение правой ноги",
        //       locationWhere: "Госпиталь #2"
        //     },
        //     {
        //       dateOfVisits: "22.06.2024",
        //       diagnosis: "Осколочное ранение правой ноги",
        //       locationWhere: "Госпиталь #3"
        //     },
        //   ]

        // };

        state.patientData = responseData;
        state.currentPatientId = responseData.id || 'TMP_ID_111';
      })

      .addCase(sendGetPatientData.rejected, (state, action) => {
        state.status = "idle";
        state.loading.patientData = false;
      })



      ;
  },
});

export const {
  addToSymptomsList,
  addToClarificationsList,
  addToUnitsList,
  addToUnitsListFromSearch,
  addToPatientDisease,
  addToPatientEditLifesAnamnez,
  addToPatientEditDisease,
  addToPatientClarificationsList,
  addToPatientEditClarificationsList,
  addToPatientEditLifesAnamnezClarificationsList,
  removeSymptomById,
  removeClarificationById,
  removePatientDiseaseById,
  removePatientEditDiseaseById,
  removePatientEditLifesAnamnezById,
  removePatientClarificationById,
  removePatientEditLifesAnamnezClarificationById,
  removePatientEditClarificationById,
  resetSymptomsList,
  resetSettings,
  resetPatient,
  resetPatientEdit,
  setCurrentSymptom,
  setCurrentPatientDisease,
  setCurrentPatientLifesAnamnez,
  setResultGraphId,
  setRASPage,
  setSensorValue,
  setSetting,
  setSettings,
  setPatient,
  setPatientEdit,
  setCurrentPersonnelItem,
  setCurrentPatientId,
  resetCurrentSymptom,
  resetCurrentPatientDisease,
  resetCurrentPatientLifesAnamnez,
  updateCurrentPersonnelItem,
  updatePatientData,
} = personnelSlice.actions;

export const selectCurrentPatientId = (state) => state.personnel.currentPatientId;


export const selectSymptomsList = (state) => state.personnel.symptomsList;
export const selectCurrentPersonnelItem = (state) => state.personnel.currentPersonnelItem;
export const selectPatientData = (state) => state.personnel.patientData;
export const selectPatient = (state) => state.personnel.patient;
export const selectPatientEdit = (state) => state.personnel.patientEdit;
export const selectPatientLifesAnamnez = (state) => state.personnel.patient.lifesAnamnez;
export const selectPatientDisease = (state) => state.personnel.patient.disease;
export const selectPatientEditLifesAnamnez = (state) => state.personnel.patientEdit.lifesAnamnez || [];
export const selectPatientEditDisease = (state) => state.personnel.patientEdit.disease || [];
export const selectCurrentSymptom = (state) => state.personnel.symptomsList.find(item => item.id === state.personnel.currentSymptom);
export const selectCurrentPatientLifesAnamnez = (state) => state.personnel.patientEdit.lifesAnamnez.find(item => item.id === state.personnel.currentPatientLifesAnamnez);
export const selectCurrentPatientDisease = (state) => state.personnel.patientEdit.disease.find(item => item.id === state.personnel.currentPatientDisease);
export const selectResultData = (state) => state.personnel.resultData;
export const selectResultGraphId = (state) => state.personnel.resultGraphId;
export const selectUnitsList = (state) => state.personnel.unitsList;
export const selectLoading = (state) => state.personnel.loading;
export const selectSettings = (state) => state.personnel.settings;
export const selectRAS = (state) => state.personnel.RAS;
export const selectClarificationData = (state) => state.personnel.clarificationData;

export default personnelSlice.reducer;
