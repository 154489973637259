import React, { useState } from 'react'
import { fields, buttons } from '../app/thema';
import { Grid } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';

import { GenerateTokenNumberAsync } from '../common/api/Personnel';
import { FindFld } from '../components/Fields';

import { CircularRightPrg } from '../components/Progress';
import { EndIconBtn } from '../components/Buttons';
import { Margin, Padding } from '@mui/icons-material';

/**
 * 
 * @param {*} props 
 * @returns 
 */
function TokenNumber(props) {

    const [loading, setLoading] = useState(false);

    const model = {
        tokenNumber: {
            thema: fields.collection2,
            size: 'small',
            variant: 'outlined',
            name: 'tokenNumber',
            label: '*Номер жетона',
        },
    }

    const handleClick = async (e) => {
        setLoading(true);
        props.formik.setErrors({ tokenNumber: null });
        let res = await GenerateTokenNumberAsync();
        // res.tokenNumber = "ХХХХ-111111";

        if (res.value) {
            props.formik.setFieldValue("tokenNumber", res.value);
            props.handleClick(e); // пробуем зафокусить, чтобы ошибки отрабатывали, но это не работает. Не так страшно, т.к. предпологаем что сервер отвечает правильными номерами жетонов
        }

        setLoading(false);
        // props.formik.setFieldValue("tokenNumber", "ХХХХ-111111");
        // props.formik.values.tokenNumber = "АааА-999999";

        // props.formik.setErrors({ tokenNumber: "Неверный3 формат жетона (АА-999999)" });


    }

    return (<>
        <Grid
            item xs={12} sm={7} md={9} lg={9} xl={9}
        >
            <FindFld
                thema={model.tokenNumber.thema}
                name={model.tokenNumber.name}
                size={model.tokenNumber.size}
                variant={model.tokenNumber.variant}
                label={model.tokenNumber.label}
                value={props.formik.values.tokenNumber}
                show={props.tokenNumberOk}
                handleBlur={props.formik.handleBlur}
                handleChange={props.formik.handleChange}
                handleKeyUp={props.handleKeyUp}
                handleClick={props.handleClick}
                touched={props.formik.touched.tokenNumber}
                error={props.formik.errors.tokenNumber}
            />
        </Grid>
        <Grid
            sx={{
                display: { sm: 'none', md: 'none', lg: 'none', xl: 'none' }
            }}
            item xs={7} sm={7} md={9} lg={9} xl={9}
        />
        <Grid            
            item xs={5} sm={5} md={3} lg={3} xl={3}
        >
            {loading === true ?
                <CircularRightPrg />
                :
                <EndIconBtn
                    thema={buttons.collection1}
                    icon={<QuizIcon />}
                    variant={"contained"}
                    value={"Жетон неизвестен"}
                    handleClick={handleClick}
                />
            }
        </Grid>
    </>
    )
}


export {
    TokenNumber
}