import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
    Grid, Card, CardHeader, Avatar, CardActions, Button, CardActionArea, CardContent, Typography, IconButton
} from '@mui/material';

import { ParsingByDateToString } from '../common/helper/Parser';

import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';


/**
 * Компонент кнопка в 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function BtnCard(props) {
    const navigate = useNavigate();
    return (
        <Card>
            <CardActionArea
                disabled={props.isDisabled}
                onClick={() => { navigate(props.href) }}>
                <CardHeader
                    sx={{
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                    }}
                    avatar={
                        <Avatar aria-label="recipe">
                            {props.avatar}
                        </Avatar>
                    }
                    title={props.title}
                />
            </CardActionArea>
        </Card>
    )
}

/**
 * Компонент кнопка в 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function BtnCardImg(props) {
    const navigate = useNavigate();
    
    return (
        <Card>
            <CardActionArea
                disabled={props.isDisabled}
                onClick={() => { navigate(props.href) }}>
                <CardHeader
                    sx={{
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                width: 48,
                                height: 48
                            }}
                            src={props.img}
                        />
                    }
                    title={props.title}
                />
            </CardActionArea>
        </Card>
    )
}
/**
 * Компонент кнопка маленький
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function BtnCardImgSm(props) {
    const navigate = useNavigate();

    return (
        <Card>
            <CardActionArea
                sx={{ m: 0, p: 0 }}
                disabled={props.isDisabled}
                onClick={() => { navigate(props.href) }}>
                <CardHeader
                    sx={{
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                        p: "4px 8px !important",
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                width: 48,
                                height: 48
                            }}
                            src={props.img}
                        />
                    }
                    title={props.title}
                />
            </CardActionArea>
        </Card>
    )
}
/**
 * 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function TransferableEpicrisisCard(props) {
    const navigate = useNavigate();

    return (
        <Card
            sx={{
                border: '1px solid',
                borderColor: props.thema.borderColor,
            }}
        >
            <CardContent>
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={6} lg={4} xl={4}
                    >
                        <Typography
                            variant={props.locationMedicalAssistance.variant} color={props.locationMedicalAssistance.thema.borderColor}
                        >
                            {props.locationMedicalAssistance.label} {props.locationMedicalAssistanceValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={3} lg={4} xl={4}
                    >
                        <Typography
                            variant={props.dateOfVisits.variant} color={props.dateOfVisits.thema.borderColor}
                        >
                            {props.dateOfVisits.label} {props.dateOfVisitsValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={3} lg={4} xl={4}
                    >
                        <Typography
                            variant={props.dateOfEvacuation.variant} color={props.dateOfEvacuation.thema.borderColor}
                        >
                            {props.dateOfEvacuation.label} {props.dateOfEvacuationValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <Typography
                            variant={props.helpProvided.variant} color={props.helpProvided.thema.borderColor}
                        >
                            {props.helpProvided.label} {props.helpProvidedValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <Typography
                            variant={props.diagnosis.variant} color={props.diagnosis.thema.borderColor}
                        >
                            {props.diagnosis.label} {props.diagnosisValue}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <Typography
                            variant={props.recommendations.variant} color={props.recommendations.thema.borderColor}
                        >
                            {props.recommendations.label} {props.recommendationsValue}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small">Распечатать</Button>
            </CardActions>
        </Card>
    )
}

/**
 * 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function UserCard(props) {
    const navigate = useNavigate();
    const [isBlock, setIsBlock] = useState(props.isBlock);

    const handleClick = () => {
        setIsBlock(!isBlock);
    }

    const SubHeader = (login, lic) => {
        return (
            <div>
                Логин: {login}<br />
                Ключ: {lic}
            </div>
        )
    }

    return (
        <Card>
            <CardActionArea
                disabled={props.isDisabled}
            >
                <CardHeader
                    sx={{
                        pb: '0px',
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                width: 48,
                                height: 48
                            }}
                            src={props.img}
                        />
                    }
                    title={props.title}
                    subheader={SubHeader(props.login, props.lic)}
                />
            </CardActionArea>
            <CardActions disableSpacing>
                <Grid
                    container
                >
                    <Grid
                        item xs={6} sm={6} md={6} lg={6} xl={6}
                    >
                        <IconButton
                            aria-label="block" onClick={handleClick}
                        >
                            {isBlock
                                ? <LockOutlinedIcon
                                    sx={{
                                        color: 'red'
                                    }}
                                />
                                : <LockOpenOutlinedIcon
                                    sx={{
                                        color: props.thema.color
                                    }}
                                />
                            }
                        </IconButton>
                    </Grid>
                    <Grid
                        sx={{
                            textAlign: 'right'
                        }}
                        item xs={8} sm={6} md={6} lg={6} xl={6}
                    >
                        <IconButton
                            aria-label="block"
                            onClick={props.handleEditClick}
                        >
                            <EditOutlinedIcon
                                sx={{
                                    color: props.thema.color
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

/**
 * 
 * @param { object } props передаваемые параметры в компонент
 * @returns 
 */
function MilitaryOrganizationsCard(props) {
    const navigate = useNavigate();

    const SubHeader = (fullName) => {
        return (
            <div>
                Полное наименование: {fullName}
            </div>
        )
    }

    return (
        <Card>
            <CardActionArea
                disabled={props.isDisabled}
            >
                <CardHeader
                    sx={{
                        pb: '0px',
                        '& .MuiCardHeader-title': {
                            fontSize: props.fontSize,
                            color: props.thema.color,
                        },
                    }}
                    avatar={
                        <Avatar
                            sx={{
                                width: 48,
                                height: 48
                            }}
                            src={props.img}
                        />
                    }
                    title={props.title}
                    subheader={SubHeader(props.fullName)}
                />
            </CardActionArea>
            <CardActions disableSpacing>
                <Grid
                    container
                >
                    <Grid
                        sx={{
                            textAlign: 'left'
                        }}
                        item xs={6} sm={6} md={6} lg={6} xl={6}
                    >                        
                    </Grid>
                    <Grid
                        sx={{
                            textAlign: 'right'
                        }}
                        item xs={6} sm={6} md={6} lg={6} xl={6}
                    >
                        <IconButton
                            aria-label="block"
                        >
                            <EditOutlinedIcon
                                sx={{
                                    color: props.thema.color
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}


export {
    BtnCard, BtnCardImg, BtnCardImgSm, TransferableEpicrisisCard, UserCard, MilitaryOrganizationsCard
}