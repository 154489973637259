import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import { Recent } from '../features/recent';

export default function PRecent() {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            <Recent />
        </>
    )
}
