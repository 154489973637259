import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import { Hospital } from '../features/hospital';

export default function PRecent() {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            <Hospital />
        </>
    )
}
