import React, { memo } from "react";
import { useSelector } from "react-redux";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import {
    selectPatientData,
} from "../../historySlice";

const OtherDiseasesComponent = ({ currentEvent, onChangeCurrentEvent }) => {
    const [open, setOpen] = React.useState(false);
    const patientData = useSelector(selectPatientData);

    let { events } = patientData;

    events = events && events.length ? events : [];

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickItem = (eventId) => {
        onChangeCurrentEvent(eventId);
    };

    return <>
        <List
            sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton onClick={handleClick}>
                <ListItemText primary={`Все ранения/заболевания (${events.length})`} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    {events.map((event, index) => {
                        let title = "";

                        if (event.history && event.history[0]) {
                            if (event.history[0].hospitalizationDate) {
                                title = event.history[0].hospitalizationDate ? new Date(event.history[0].hospitalizationDate).toLocaleDateString() : "";
                            }

                            if (title && event.history[0].disease) {
                                title = title + " - " + event.history[0].disease;
                            } else {
                                title = event.history[0].disease;
                            }
                        }

                        return <ListItemButton
                            // disabled={index === currentEvent}
                            key={event.id}
                            sx={{
                                pl: 4,
                                fontWeight: event.id === currentEvent.id ? "700" : "700",
                            }}
                            onClick={() => handleClickItem(event)}
                        >
                            <ListItemIcon>
                                {event.id === currentEvent.id ? <StarIcon /> : <StarBorderIcon />}
                            </ListItemIcon>
                            <ListItemText
                                primary={title}
                                sx={{
                                    pl: 4,
                                }}
                                primaryTypographyProps={{
                                    sx: {
                                        pl: 4,
                                        fontWeight: event.id === currentEvent.id ? "700" : "500",
                                    }
                                }}
                            />
                        </ListItemButton>;
                    })}
                </List>
            </Collapse>
        </List>
    </>;
}

export const OtherDiseases = memo(OtherDiseasesComponent);
