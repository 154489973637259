import { Stack } from "@mui/material";

export const ModalStack = ({ children }) => {
    return <Stack
        direction="row"
        sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            mx: 0,
            my: 1,
            "& p": {
                mt: 1,
            }
        }}>
        {children}
    </Stack>;
}