import React, { memo, useEffect, useMemo, useState } from "react";

import { ruRU } from '@mui/x-data-grid-pro/locales';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Card,
    Grid,
    Typography,
    Box,
} from "@mui/material";

import {
    gridClasses,
    DataGridPro,
    useGridApiRef,
} from '@mui/x-data-grid-pro';

import { CustomToolbar } from "./components/CustomToolbar";

import { PrintList } from "./components/PrintList";

import {
    sendGetHospitalData,
    selectHospitalData,
    selectLoading,
    resetHospitalData,
} from "../hospital/hospitalSlice";

import { fields } from '../../app/thema';


import "./styles.css";
import { appStorage } from "../../helpers";
import { urlHistory } from "../../common/helper/UrlRouter";

const fixSettings = (settings = {}, apiRef) => {
    let fixedSettings = { ...settings };

    // some fixes for users LS data
    let currentFilterModel = apiRef?.current?.state?.filter?.filterModel?.items || [];
    let columnsLookup = apiRef?.current?.store?.value?.columns?.lookup || {};
    let hasBrokenFilters = false;

    currentFilterModel.forEach(item => {
        let filterOperators = columnsLookup[item.field]?.filterOperators || [];
        let cleanFilterOperators = filterOperators.map(item => item.value) || [];

        if (!cleanFilterOperators.includes(item.operator)) {
            console.log("hasBrokenFilters", cleanFilterOperators, item.operator);
            hasBrokenFilters = true;
        }
    });

    if (hasBrokenFilters) {
        delete fixedSettings.filter.filterModel;
    }

    // Удалаяем пустой объект columnVisibilityModel, иначе будет считаться что нуно показать все колонки
    if (settings.columns && settings.columns.hasOwnProperty("columnVisibilityModel")) {
        if (Object.keys(settings.columns.columnVisibilityModel).length === 0) {
            delete fixedSettings.columns.columnVisibilityModel;
        }
    }
    return fixedSettings;
}


const HospitalComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    const apiRef = useGridApiRef();
    let hospitalData = useSelector(selectHospitalData);
    let loading = useSelector(selectLoading);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    let initialRows = useMemo(() => [], []);
    let columns = [];
    let columnsToHide = {};
    let columnsToPrint = [];
    let columnsToGroupChange = [];

    let authentication = appStorage.getItem('authentication') || {};
    let userId = authentication.idPersonnel || "";
    let userSettingsLS = appStorage.getItem(userId) || {};
    let hospitalSettingsLS = fixSettings(userSettingsLS.hospitalSettings, apiRef) || {};

    const [hospitalSettings, setHospitalSettings] = useState(hospitalSettingsLS);


    if (hospitalData.table) {
        initialRows = hospitalData.table.rows;
        columns = [...hospitalData.table.columns];

        columns.forEach(column => {
            if (column.hidden) {
                columnsToHide[column.field] = false;
            }
            if (column.toPrint) {
                columnsToPrint.push(column.field);
            }
            if (column.groupChange) {
                columnsToGroupChange.push(column.field);
            }
        })
    }

    useEffect(() => {
        dispatch(sendGetHospitalData());
    }, [dispatch]);

    useEffect(() => {
        let columnsToHide = {};

        if (hospitalData.table) {
            columns.forEach(column => {
                if (column.hidden) {
                    columnsToHide[column.field] = false;
                }
            })
            setColumnVisibilityModel(columnsToHide)

            initialRows = hospitalData.table.rows;
            setRows(initialRows);
        }
    }, [hospitalData.table]);


    useEffect(() => {
        return () => {
            dispatch(resetHospitalData());
        };
    }, []);

    useEffect(() => {
        if (initialRows && apiRef?.current?.restoreState != null && hospitalSettings != null && initialRows.length) {
            setTimeout(() => {
                apiRef?.current?.restoreState(fixSettings(hospitalSettings, apiRef));
            }, 200);
        }
    }, [apiRef, initialRows, hospitalSettings]);


    const handleStateChange = (eventType) => {
        console.log("eventType", eventType);
        let currentState = apiRef.current.exportState();

        let hospitalSettings = {
            sorting: currentState.sorting,
            filter: currentState.filter,
            // pinnedColumns: currentState.pinnedColumns,
            columns: {
                columnVisibilityModel: currentState.columns.columnVisibilityModel,
                // orderedFields: currentState.columns.orderedFields,
                // dimensions: currentState.columns.dimensions,
            },
        };


        if (currentState && currentState.columns) {
            appStorage.setItem(userId, {
                ...userSettingsLS,
                "hospitalSettings": fixSettings(hospitalSettings, apiRef),
            });
        }
    }

    const handleRowClick = (data, rwer, wttgwe) => {
        navigate(`${urlHistory}/${data?.row?.jp_id_personnel}/${data?.row?.e300_id}/${data?.row?.h300_id}`);
    }

    if (apiRef.current?.subscribeEvent) {
        apiRef.current.subscribeEvent("filterModelChange", () => handleStateChange("filterModelChange"));
        apiRef.current.subscribeEvent("columnVisibilityModelChange", () => handleStateChange("columnVisibilityModelChange"));
        apiRef.current.subscribeEvent("sortModelChange", () => handleStateChange("sortModelChange"));
    }

    return <>
        <PrintList />
        <div className="no-print">
            <Grid
                sx={{
                    mt: '15px',
                }}
                container
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <form>
                        <Card
                            sx={{
                                mx: '5px',
                                py: '15px',
                                pl: '10px',
                                pr: '40px',
                                borderRadius: '10px'
                            }}
                            variant="outlined"
                        >
                            <Grid
                                container spacing={2}
                            >
                                <Grid
                                    style={{
                                        textAlign: 'center',
                                    }}
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="h4"
                                        color={fields.collection2.borderColor}
                                    >
                                        Госпитальное отделение
                                    </Typography>

                                </Grid>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: "calc(100vh - 150px)",
                                        width: '100%',
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        className="hospital-table-container"
                                        sx={(theme) => ({
                                            ml: "50px",
                                            height: "calc(100vh - 150px)",
                                            width: '100%',
                                            '& .MuiDataGrid-cell--editable': {
                                                bgcolor: 'rgba(217, 243, 190, 0.3)',
                                                ...theme.applyStyles('dark', {
                                                    bgcolor: '#444',
                                                }),
                                            },
                                            '& .MuiDataGrid-cell': {
                                                borderLeft: '1px solid lightgray',
                                                cursor: 'pointer',
                                            },
                                            '& .MuiDataGrid-cell--editable input, & .MuiDataGrid-cell--editable textarea, & .MuiDataGrid-cell--editable div:nth-child(1)': {
                                                backgroundColor: 'rgb(245, 229, 182)',
                                                padding: "0 2px",
                                            },
                                            '& .MuiDataGrid-cell--editable div:nth-child(1)': {
                                                padding: "0 10px 0 2px",
                                                fontSize: "0.875rem",
                                            },
                                            '& .MuiDataGrid-cell--editable div:nth-child(1) [type="datetime-local"] ': {
                                                padding: "0",
                                            },
                                            '& .MuiDataGrid-columnHeader': {
                                                "& div": {
                                                    whiteSpace: "wrap",
                                                }
                                            },
                                            '& .MuiDataGrid-row.Mui-selected': {
                                                backgroundColor: "rgba(25, 118, 210, 0.4) !important",
                                            },
                                            '& .MuiDataGrid-row.Mui-selected:hover': {
                                                backgroundColor: "rgba(25, 118, 210, 0.5) !important",
                                            },
                                            '& .MuiDataGrid-row:hover': {
                                                backgroundColor: "rgba(141, 155, 142, 0.2) !important",
                                            },
                                        })}>
                                        <DataGridPro
                                            sx={{
                                                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                                    outline: 'none',
                                                },
                                                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                                                {
                                                    outline: 'none',
                                                },
                                                [`& .${gridClasses["row--borderBottom"]}`]:
                                                {
                                                    backgroundColor: "transparent !important",
                                                },
                                                [`& .${gridClasses.columnHeaders}`]:
                                                {
                                                    backgroundColor: "#bfc4b5",
                                                },
                                                [`& .${gridClasses.columnHeaderTitle}`]:
                                                {
                                                    fontWeight: 600,
                                                    letterSpacing: "0.05071em",
                                                },
                                            }}
                                            apiRef={apiRef}
                                            getRowId={(row) => row.h300_id}
                                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rows}
                                            columns={columns}
                                            editMode="row"
                                            getRowHeight={() => 'auto'}
                                            onRowDoubleClick={handleRowClick}
                                            slots={{
                                                toolbar: CustomToolbar.bind(this, {}),
                                            }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                },
                                            }}
                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            loading={loading}
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                setColumnVisibilityModel(newModel)
                                            }
                                        />
                                    </Box>
                                </Box>

                            </Grid>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </div >
    </>;
}


export const Hospital = memo(HospitalComponent);
