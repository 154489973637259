//import { SERVER } from './server';
import { GetUrlServer } from '../common/helper/UrlController';

// const SERVERS = {
//     prod: {
//         api: "https://service.socmedica.com:9108",
//         auth: "https://service.socmedica.com:9108",
//     },
//     dev: {
//         api: "https://service.socmedica.dev:9108",
//         auth: "https://service.socmedica.dev:9108",
//     },
//     stb: {
//         api: "http://ecp.box:30946",
//         auth: "http://ecp.box:44380",
//     },
// };

function getUrls() {
    //const { api: APIServer } = SERVERS[cfg.type];
    const APIServer = GetUrlServer();

    return {
        root: APIServer,
        graph: {
            // GetGraphData: "https://jsonplaceholder.typicode.com/posts",
            GetGraphData: APIServer + "/GetGraph",
        },
        main: {
            ClearCache: APIServer + "/ClearCache",
            CheckParametricData: APIServer + "/CheckParametricData",
            // CommonSearch: APIServer + "/CommonSearch",
            CommonSearch: APIServer + "/Api/Personnel/FindByLetters",
            SubmitSymptoms: APIServer + "/SubmitSymptoms",
            SubmitRAS: APIServer + "/SubmitRAS",
            GetSymptomsFromText: APIServer + "/GetIssuesFromText",
            GetPatientData: APIServer + "/Api/Personnel/History300ById",
            GetEvacuationData: APIServer + "/Api/Export/TableEvacuation",
            PatientsEvacuation: APIServer + "/Api/Personnel/PatientEvacuation",
            PatientsEvacuationStatus: APIServer + "/Api/Personnel/PatientsEvacuationStatus",
            PatientsEvacuationStamp: APIServer + "/Api/Personnel/PatientsEvacuationStamp",
            GetHospitalData: APIServer + "/Api/Export/TableClinical", //
            GetPSOData: APIServer + "/Api/Export/TableReceptionSorting", //
            PatientsHospital: APIServer + "/Api/Personnel/PatientEvacuation", //
            PatientsHospitalStatus: APIServer + "/Api/Personnel/PatientsEvacuationStatus", //
            PatientsHospitalStamp: APIServer + "/Api/Personnel/PatientsEvacuationStamp", //
            GetHistoryPatientsByUser: APIServer + "/Api/Personnel/HistoryPatientsByUser",
            GetHospitalPatientsByUser: APIServer + "/Api/Personnel/HistoryPatientsByUser",
            UpdateForm300: APIServer + "/Api/Forms/Upd300",
            PersonnelUpd: APIServer + "/Api/Personnel/Upd",
            // GetPatientData: "https://jsonplaceholder.typicode.com/posts",
            // GetSymptomsFromText: APIServer + "/GetSymptomsFromText",
        },
    }
}

export const API_URLS = getUrls();