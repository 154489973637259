import React from 'react';
import {
    FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField
} from '@mui/material';


/**
 * Компонент поле с выпадающим списком
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function SelectCbx(props) {
    return (
        <FormControl
            fullWidth size={props.size}
        >
            <InputLabel
                sx={{
                    color: props.thema.color,
                }}
                id="demo-simple-select-label"
            >
                {props.label}
            </InputLabel>
            <Select
                sx={{
                    color: props.thema.color,
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: props.thema.borderColor,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: props.thema.borderColor,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: props.thema.borderColor,
                    },
                }}
                variant={props.variant} name={props.name} label={props.label} value={props.value}
                onBlur={props.handleBlur} onChange={props.handleChange}
                helperText={props.touched && props.error} error={Boolean(props.error && props.touched)}
            >
                {props.elems.map((item) => (
                    <MenuItem key={item.key} value={item.label}>
                        {item.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

/**
 * Компонент поле выпадающим списком с автоподстановкой
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function AutoCompleteCbx(props) {
    const handleChange = (e) => {
        let val = e.target.innerHTML || { id: "", label: "" };
        let idx = props.elems.findIndex(o => o.label === val);
        props.setFieldValue(props.name, props.elems[idx]);
        props.handleSetState(props.elems[idx]);
    }
    return (
        <Autocomplete
            sx={{
                color: props.thema.color,
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: props.thema.borderColor,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: props.thema.borderColor,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: props.thema.borderColor,
                },
            }}
            fullWidth
            disablePortal
            name={props.name}
            size={props.size}
            variant={props.variant}
            options={props.elems || []}
            value={props.val}
            disabled={props.isDisabled}
            getOptionLabel={(option) => option.label}
            getOptionKey={(option) => option.id}
            required={!!props.required}
            renderInput={(params) =>
                <TextField
                    sx={{
                        '& label.Mui-focused': {
                            color: props.thema.color,
                        },
                    }}
                    {...params}
                    required={props.required || false}
                    label={props.label}
                    onClick={props.handleClickLabel}
                    helperText={props.touched && props.error}
                    error={Boolean(props.error && props.touched)}
                />
            }
            onBlur={props.handleBlur} onChange={handleChange}
        />
    )
}

export {
    SelectCbx, AutoCompleteCbx,
}