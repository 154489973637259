import { configureStore } from '@reduxjs/toolkit';

import cardindexPersonnelSlice from './reducers/cardindexPersonnelSlice';
import historyReducer from '../features/history/historySlice';
import recentReducer from '../features/recent/recentSlice';
import hospitalReducer from '../features/hospital/hospitalSlice';
import evacuationReducer from '../features/evacuation/evacuationSlice';
import personnelReducer from '../features/personnel/personnelSlice';
import PSOReducer from '../features/PSO/PSOSlice';


export const store = configureStore({
    reducer: {
        cardindexPersonnel: cardindexPersonnelSlice,
        history: historyReducer,
        hospital: hospitalReducer,
        recent: recentReducer,
        evacuation: evacuationReducer,
        personnel: personnelReducer,
        PSO: PSOReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});