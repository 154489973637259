import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

import {
    Divider,
    Typography,
} from '@mui/material';

import "./styles.css";


const PrintTpl_2Component = ({ printData }) => {


    console.log(printData);

    let { patientData, currentHistoryItem, currentEvent, modalData = {} } = printData;


    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };


    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    let currentDate = new Date().toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });


    let mhNumber = currentHistoryItem.number ? currentHistoryItem.number : "_________________________";
    let statusEvaqArr = [];
    let statusEvaqArrTitle = [];

    if(currentHistoryItem.status){
        statusEvaqArr.push(currentHistoryItem.status);
        statusEvaqArrTitle.push("Статус");
    }

    if(currentHistoryItem.evacuationLocation){
        statusEvaqArr.push(currentHistoryItem.evacuationLocation);
        statusEvaqArrTitle.push("Направление убытия");
    }
    let statusEvaq = statusEvaqArr.join(" / ");
    let statusEvaqTitle = statusEvaqArrTitle.join(" / ");

    return <>

        <div className="page print page-nobreak">
            <style type="text/css" media="print">
                {" @page { size: portrait; } "}
            </style>
            <table className="table">
                <tbody>
                    <tr>
                        <td className="bright">




                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td align="center"><img src="/img/mo-gerb.png" alt="" width="80" /></td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">Военная медицинская организация _________________________{/*01069 (н. п. Красный Октябрь)*/}</td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">ПЕРЕВОДНОЙ ЭПИКРИЗ № {mhNumber}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Фамилия Имя Отчество, дата рождения:</div>
                                            <div className="bcontent">{patientData.fio} {patientData.birthday}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Личный номер:</div>
                                            <div className="bcontent">{patientData.suid}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Воинское звание:</div>
                                            <div className="bcontent">{patientData.militaryRank}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Воинская часть:</div>
                                            <div className="bcontent">{patientData.militaryUnitCode}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Находился на лечении:</div>
                                            <div className="bcontent">{currentHistoryItem.hospitalizationDate} по {currentHistoryItem.dischargeDate}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Диагноз:</div>
                                            <div className="bcontent">{currentHistoryItem.disease}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Анамнез:</div>
                                            <div className="bcontent">
                                                Дата и время ранения: {currentEvent.eventDate ? new Date(currentEvent.eventDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "-"}<br />
                                                Место ранения: {currentEvent.location || "-"}<br />
                                                Обстоятельства ранения/болезни: {currentEvent.circumstances || "-"}<br />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Результаты диагностических исследований:</div>
                                            <div className="bcontent">{currentHistoryItem.diagnosisStudies || ""}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Лечение:</div>
                                            <div className="bcontent">{currentHistoryItem.helping || ""}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">{statusEvaqTitle}:</div>
                                            <div className="bcontent">{statusEvaq || ""}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle">Направление эвакуации:</div>
                                            <div className="bcontent">{currentHistoryItem.referral || ""}</div>
                                        </td>
                                    </tr>


                                    {modalData.p1 && <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle bold">Справка о ранении (контузии, травме, увечье), в соответствии с указом Президента РФ №98 от 05.03.2023 г. <br />№ {modalData.p1_number || "________"} от {modalData.p1_date || "________"} г. оформлена.</div>
                                        </td>
                                    </tr>}


                                    {modalData.p2 && <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle bold">Справка на социальные выплаты в соответствии с Указом Президента РФ №98 не оформлялась.</div>
                                        </td>
                                    </tr>}

                                    {modalData.p3 && <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle bold">Освидетельствован ВВК {modalData.p3_date || "________"} года, в соответствии с Перечнем, утвержденным постановлением Правительства Российской Федерации от 29.07.1998 года №855, увечье - в перечень {modalData.p3_entire || "________"}</div>
                                        </td>
                                    </tr>}


                                    {modalData.p4 && <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle bold">Медицинское освидетельствование на определение тяжести травмы (увечья) не проводилось.</div>
                                        </td>
                                    </tr>}

                                    {modalData.p5 && <tr>
                                        <td className="bborder-flex">
                                            <div className="btitle bold">Эвакуация: {modalData.p5_evaqType || "________"}, очередь {modalData.p5_queue || "________"}</div>
                                        </td>
                                    </tr>}



                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Лечащий врач</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">{currentHistoryItem.doctor}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    {/*
                                    <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Начальник медицинской службы<br /> Медроты 41 МСП, Красный октябрь</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">
                                                            ст. л-т м/с
                                                            <br />
                                                            Иванов Э.А.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Лечащий врач<br /> Медроты 41 МСП, Красный октябрь</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">
                                                            л-т м/с
                                                            <br />
                                                            {currentHistoryItem.doctor}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    */}
                                </tbody>
                            </table>




                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
}

export const PrintTpl_2 = memo(PrintTpl_2Component);
