import { API_URLS } from "../../constants";
import { POST, appStorage } from "../../helpers";


let authentication = appStorage.getItem('authentication') || {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}



export function fetchSendGetHospitalData({ data = {}, success = null }) {

  return POST({
    // path: API_URLS.main.GetHistoryPatientsByUser,
    path: API_URLS.main.GetHospitalData,
    data: {
      ...data,
      id: data.id,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}

export function fetchSendSaveSelected({ data = {}, success = null }) {

  return POST({
    // path: API_URLS.main.GetHistoryPatientsByUser,
    path: API_URLS.main.PatientsHospitalStatus,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}


export function fetchSendSaveRow({ data = {}, success = null }) {

  return POST({
    // path: API_URLS.main.GetHistoryPatientsByUser,
    path: API_URLS.main.PatientsHospital,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}





export function fetchSendSavePatientsHospitalStamp({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.PatientsHospitalStamp,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}



