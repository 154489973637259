// REST API Контроллер взаимодействия с формами
import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
} from '../api/QueryHandler';
import {
    Get300POST, Reg300POST, Upd300POST, Get200POST, Reg200POST, Upd200POST,
    GetDiseasesPOST, RegDiseasesPOST, UpdDiseasesPOST, GetTraumaPOST, RegTraumaPOST,
    UpdTraumaPOST, Get300ListPOST, GetEventPOST, RegEventPOST, UpdEventPOST
} from '../helper/UrlController';
import {
    ResponseAlert, ItemForma300, ItemForma200, ItemFormaDiseases, ItemFormaTrauma,
} from '../models/Response';
import {
    RequestGet300, RequestReg300, RequestUpd300, RequestGet200, RequestReg200, RequestUpd200,
    RequestGetDiseases, RequestRegDiseases, RequestUpdDiseases, RequestGetTrauma, RequestRegTrauma,
    RequestUpdTrauma, RequestGetEvent, RequestRegEvent
} from '../models/Request';


/**
 * API для получения формы 300
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function Get300Async(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestGet300(idPersonnel).getModel();
        let res = await PostFetchAsync(Get300POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }
        model.alert = new ResponseAlert(res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level,
            res.alert.sticky
        ).getModel();
        model.result = new ItemForma300(
            res.result.idEvent, res.result.dateOfInjury, res.result.gravityOfInjury, res.result.locationWhereInjury,
            res.result.circumstances, res.result.nature, res.result.idHistory, res.result.dateOfVisits,
            res.result.locationMedicalAssistance, res.result.complaint, res.result.diagnosis, res.result.diagnosticStudies,
            res.result.helpProvided, res.result.therapy, res.result.pharmacotherapy, res.result.recommendations, res.result.doctor,
            res.result.locationWhere, res.result.dateOfEvacuation, res.result.stateByEvacuation, res.result.status
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
async function Get300ListAsync(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestGet300(idPersonnel).getModel();
        let res = await PostFetchAsync(Get300ListPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level,
            res.alert.sticky
        ).getModel();
        model.result = res.result;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для регистрации формы 300
 * @param { Object } data Модель запроса
 * @returns Модель ответа
 */
async function Reg300Async(data) {
    let model = {
        alert: {}
    };
    try {
        let req = new RequestReg300(data).getModel();
        let res = await PostFetchAsync(Reg300POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для обновления формы 300
 * @param { Object } data Модель запроса
 * @returns Модель ответа
 */
async function Upd300Async(data) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpd300(data).getModel();
        let res = await PostFetchAsync(Upd300POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


/**
 * API для получения формы 200
 * @param { String } idPersonnel УИН записи журнала личного состава
 * @returns Модель ответа
 */
async function Get200Async(idPersonnel) {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestGet200(idPersonnel).getModel();
        let res = await PostFetchAsync(Get200POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemForma200(res.result.idEvent, res.result.dateOfDeath, res.result.locationWhereInjury,
            res.result.circumstances, res.result.nature, res.result.idHistory, res.result.stateByEvacuation,
            res.result.dateOfVisits, res.result.diagnosis, res.result.locationWhere, res.result.dateOfEvacuation,
            res.result.deathCertificate
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для обновления формы 200
 * @param { Object } data Модель запроса 
 * @returns Модель ответа
 */
async function Reg200Async(data) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestReg200(data).getModel();
        let res = await PostFetchAsync(Reg200POST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для обновления формы 200
 * @param { Object } data Модель запроса
 * @returns Модель ответа
 */
async function Upd200Async(data) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpd200(data).getModel();
        let res = await PostFetchAsync(Upd200POST().url, req);

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


/**
 API для получения формы заболеваний
 * @param { String } idPersonnel УИН записи журнала личного состава
 * @returns Модель ответа
 */
async function GetDiseasesAsync(idPersonnel) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestGetDiseases(idPersonnel).getModel();
        let res = await PostFetchAsync(GetDiseasesPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemFormaDiseases(
            res.result.idEvent, res.result.idHistory, res.result.dateOfVisits, res.result.locationMedicalAssistance,
            res.result.complaint, res.result.anamnesis, res.result.objectively, res.result.diagnosis, res.result.pharmacotherapy,
            res.result.recommendations, res.result.doctor, res.result.dateOfEvacuation, res.result.typeOfDirection, res.result.specialist,
            res.result.locationWhere, res.result.therapy
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для регистрации заболеваний
 * @param { String } data Модель запроса
 * @returns Модель ответа
 */
async function RegDiseasesAsync(data) {
    let model = {
        alert: {}
    };
    try {
        let req = new RequestRegDiseases(data).getModel();
        let res = await PostFetchAsync(RegDiseasesPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для обновления заболеваний
 * @param { String } data Модель запроса
 * @returns Модель ответа
 */
async function UpdDiseasesAsync(data) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpdDiseases(data).getModel();
        let res = await PostFetchAsync(UpdDiseasesPOST().url, req);
        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


/**
 API для получения формы травма
 * @param { String } idPersonnel УИН записи журнала личного состава
 * @returns Модель ответа
 */
async function GetTraumaAsync(idPersonnel) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestGetTrauma(idPersonnel).getModel();
        let res = await PostFetchAsync(GetTraumaPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = new ItemFormaTrauma(
            res.result.idEvent, res.result.idHistory, res.result.dateOfInjury, res.result.gravityOfInjury, res.result.locationWhereInjury,
            res.result.circumstances, res.result.dateOfVisits, res.result.locationMedicalAssistance, res.result.complaint,
            res.result.anamnesis, res.result.objectively, res.result.diagnosis, res.result.pharmacotherapy, res.result.recommendations,
            res.result.doctor, res.result.dateOfEvacuation, res.result.typeOfDirection, res.result.specialist, res.result.locationWhere,
            res.result.therapy
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для регистрации травмы
 * @param { String } data Модель запроса
 * @returns Модель ответа
 */
async function RegTraumaAsync(data) {
    let model = {
        alert: {}
    };
    try {
        let req = new RequestRegTrauma(data).getModel();
        let res = await PostFetchAsync(RegTraumaPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * API для обновления формы травма
 * @param { String } data Модель запроса
 * @returns Модель ответа
 */
async function UpdTraumaAsync(data) {
    let model = {
        alert: {},
    };
    try {
        let req = new RequestUpdTrauma(data).getModel();
        let res = await PostFetchAsync(UpdTraumaPOST().url, req);
        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для получения всех событий и последнего этапа в рамках этого события
 * @param { String } eventType 
 * none - Не указано, 
 * injury - Ранение, 
 * trauma - Травма, 
 * disease - Заболевание, 
 * nuclear - Ядерное, 
 * bacterial - Бактериальное, 
 * chemical - Химическое, 
 * burn - Ожог, 
 * frostbite - Отморожение, 
 * infectious - Инфекция, 
 * death - Смерть
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function GetEventAsync(eventType, idPersonnel) {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let req = new RequestGetEvent(eventType, idPersonnel).getModel();
        let res = await PostFetchAsync(GetEventPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.result = res.results;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для регистрации события или этапа в рамках этого события
 */
async function RegEventAsync(data) {
    let model = {
        alert: {},
        result: [],
    };
    try {
        let req = new RequestRegEvent(data).getModel();
        let res = await PostFetchAsync(RegEventPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * API для обновления события или этапа в рамках этого события
 */


export {
    Get300Async, Reg300Async, Upd300Async, Get200Async, Reg200Async, Upd200Async, GetDiseasesAsync, RegDiseasesAsync,
    UpdDiseasesAsync, GetTraumaAsync, RegTraumaAsync, UpdTraumaAsync, Get300ListAsync, GetEventAsync, RegEventAsync
}