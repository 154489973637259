import {
    Box,
    Typography,
} from "@mui/material";

import {
    selectPrintList,
} from "../../hospitalSlice";

import { useSelector } from "react-redux";
import dayjs from "dayjs";

function PrintListItemHeader({ width, styles, children }) {
    return <Box
        sx={{
            minWidth: width ? width + "px" : "unset",
            border: "1px solid black",
            p: "2px",
            ...styles
        }}>
        <Typography variant="body2"
            sx={{
                fontSize: "2.3mm",
                fontWeight: 600,
            }}>{children}</Typography>
    </Box>;
}

function PrintListItem({ width, styles, children }) {
    return <Box
        sx={{
            border: "1px solid black",
            p: "2px",
            ...styles
        }}>
        <Typography variant="body2" sx={{ fontSize: "2.3mm" }}>{children}</Typography>
    </Box>;
}
const dateFormat = (dateString) => {
    if (dayjs(dateString).isValid()) {
        return new Date(dateString).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", })
    }
    return dateString;
}
const dateFormatShort = (dateString) => {
    if (dayjs(dateString).isValid()) {
        return new Date(dateString).toLocaleString(undefined, { dateStyle: "short", })
    }
    return dateString;
}
export function PrintList() {
    let printList = useSelector(selectPrintList);

    if (!printList.length) {
        return null;
    }

    return (
        <>
            <style type="text/css" media="print">
                {" @page { size: portrait; } "}
            </style>
            <Box
                className="page-hospital print"
                sx={{
                    ml: "9mm",
                    mt: "5mm",
                    // mb: 2,
                    height: "auto",
                    width: "194mm",
                    display: "table",
                    border: "1px solid black",
                    borderCollapse: "collapse",
                }}
            >

                <Box
                    sx={{
                        display: "table-row",
                        "& div": {
                            display: "table-cell",
                        }
                    }}>
                    {/* <PrintListItemHeader width="35">№</PrintListItemHeader> */}
                    <PrintListItemHeader width="20">№ и/б</PrintListItemHeader>
                    <PrintListItemHeader _width="80">Личный номер</PrintListItemHeader>
                    <PrintListItemHeader _width="140">ФИО</PrintListItemHeader>
                    <PrintListItemHeader _width="140">Дата рождения</PrintListItemHeader>
                    <PrintListItemHeader>Диагноз</PrintListItemHeader>
                    <PrintListItemHeader _width="70">Дата поступления</PrintListItemHeader>
                    <PrintListItemHeader _width="110">Лечащий врач</PrintListItemHeader>
                    <PrintListItemHeader _width="40">Степень тяжести</PrintListItemHeader>
                    <PrintListItemHeader _width="70">Статус убытия</PrintListItemHeader>
                </Box>

                {printList.map((item, index) => <Box
                    key={index}
                    sx={{
                        display: "table-row",
                        "& div": {
                            display: "table-cell",
                        }
                    }}>
                    {/* <PrintListItem width="35">{index + 1}</PrintListItem> */}
                    <PrintListItem _width="40">{item.h300_mh_number}</PrintListItem>
                    <PrintListItem _width="80">{item.jp_token_number}</PrintListItem>
                    <PrintListItem _width="140">{item.fio}</PrintListItem>
                    <PrintListItem _width="140">{dateFormatShort(item.jp_birthday)}</PrintListItem>
                    <PrintListItem>{item.h300_diagnosis_clinical}</PrintListItem>
                    <PrintListItem _width="70">{dateFormat(item.h300_date_action1)}</PrintListItem>
                    <PrintListItem _width="110">{item.h300_doctor}</PrintListItem>
                    <PrintListItem _width="40">{item.e300_gravity_injury}</PrintListItem>
                    <PrintListItem _width="70">{item.h300_status}</PrintListItem>
                </Box>
                )}
            </Box>
        </>
    );
}