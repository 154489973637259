import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import ManagementTools from '../widgets/ManagementTools';
import FormaByUsers from '../widgets/FormaByUsers';
import FormaByMilitaryOrganizations from '../widgets/FormaByMilitaryOrganizations';

/**
 * Страница управление сервером
 * @returns 
 */
export default function PManagement(props) {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            {(() => {
                switch (props.step) {
                    case 1:
                        return <FormaByUsers />
                    case 2:
                        return <FormaByMilitaryOrganizations />
                    default:
                        return <ManagementTools />
                }
            })()}
        </>
    )
}
