import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import SelectorForms from '../widgets/SelectorForms';
import FormaByEvent from '../widgets/FormaByEvent';
import FormaByPatient200 from '../widgets/FormaByPatient200';


export default function PRegistration() {
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        UserCheck(navigate);
    }, []);


    if (!params.eventType) {
        return <SelectorForms />;
    } else if (params.eventType === "death") {
        return <FormaByPatient200 />;
    }

    return <FormaByEvent eventType={params.eventType} />;
}