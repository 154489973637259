import { useDispatch } from "react-redux";

import {
    Button,
    Box,
} from "@mui/material";

import PrintIcon from '@mui/icons-material/Print';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import {
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
} from '@mui/x-data-grid-pro';

import {
    setPrintList,
} from "../../hospitalSlice";

import { appStorage } from "../../../../helpers";


export function CustomToolbar() {
    let authentication = appStorage.getItem('authentication') || {};
    let userId = authentication.idPersonnel || "";

    const dispatch = useDispatch();
    const apiRef = useGridApiContext();

    return (<>
        <GridToolbarContainer sx={{ mb: 1 }}>
            <GridToolbarQuickFilter />
            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant="outlined"
                disabled={!appStorage.getItem(userId)}
                size="small"
                startIcon={<SettingsBackupRestoreIcon />}
                onClick={() => {
                    appStorage.removeItem(userId);
                    window.location.reload();
                }}
            >Сбросить настройки</Button>

            <Button
                variant="outlined"
                size="small"
                disabled={!apiRef.current?.state?.rowSelection?.length}
                startIcon={<PrintIcon />}
                onClick={() => {
                    let visibleRowsLookup = apiRef.current?.state?.visibleRowsLookup || {};
                    let sortedRows = apiRef.current.getSortedRows();

                    let visible = [];
                    sortedRows.forEach(item => {
                        if (visibleRowsLookup[item.h300_id]) {
                            visible.push(item);
                        }
                    });

                    visible = visible.filter(item => {
                        if (apiRef.current?.state?.rowSelection.includes(item.h300_id)) {
                            return true;
                        }
                        return false;
                    });

                    dispatch(setPrintList(visible));

                    setTimeout(() => {
                        window.print();
                    }, 600);
                }}
            >Распечатать</Button>
        </GridToolbarContainer>
    </>
    );
}