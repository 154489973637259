import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { appStorage } from '../helpers';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { fields, buttons, dialogs } from '../app/thema';
import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';

import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import { GetExportAsync } from '../common/api/Export';

import { Grid, Card, Typography } from '@mui/material';

import { TextFld, DateFld, DateTimeFld, MultilineFld } from '../components/Fields';
import { AutoCompleteCbx } from '../components/ComboBoxs';
import { EndIconBtn } from '../components/Buttons';
import { CircularRightPrg } from '../components/Progress';


import { MilitaryPartAsync, EvacuationLocationsAsync, LocationsAsync } from '../common/api/Handbook';

import statusJson from '../shared/statuses.json';
import typesJson from '../shared/types.json';

let authentication = appStorage.getItem('authentication') || {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}

export default function Export() {
    const [handbookMilitaryPart, setHandbookMilitaryPart] = useState([]); // Список
    const [militaryUnit, setMilitaryUnit] = useState(handbookMilitaryPart[0]); // Выбранное значение

    const [handbookEvacuationLocations, setHandbookEvacuationLocations] = useState([]); // Список
    const [evacuationLocation, setEvacuationLocation] = useState(handbookEvacuationLocations[0]); // Выбранное значение

    const [handbookLocations, setHandbookLocations] = useState([]); // Список
    const [location, setLocation] = useState(handbookLocations[0]); // Выбранное значение




    const [status, setStatus] = useState(statusJson[0]);
    const [type, setType] = useState(typesJson[0]);

    const [loading, setLoading] = useState(false);


    const handleClickExport = async () => {
        console.log(formik.values);


        let birthday = formik.values.birthday === null ? null : new Date(formik.values.birthday).toISOString();
        let dateOfInjury = formik.values.dateOfInjury === null ? null : new Date(formik.values.dateOfInjury).toISOString();
        let dateOfInjury2 = formik.values.dateOfInjury2 === null ? null : new Date(formik.values.dateOfInjury2).toISOString();
        let dateOfEvacuation = formik.values.dateOfEvacuation === null ? null : new Date(formik.values.dateOfEvacuation).toISOString();
        let dateOfEvacuation2 = formik.values.dateOfEvacuation2 === null ? null : new Date(formik.values.dateOfEvacuation2).toISOString();


        let militaryUnit = formik.values.militaryUnit?.label === '-' ||
            formik.values.militaryUnit?.label === ' '
            ? null
            : formik.values.militaryUnit?.label || null;

        let data = {
            "militaryUnit": militaryUnit,
            "location": formik.values.location?.label === "-" ? null : formik.values.location?.label,
            "dateActionStart": dateOfInjury,
            "dateActionEnd": dateOfInjury2,
            // "birthday": birthday,
            "dischargeDateStart": dateOfEvacuation,
            "dischargeDateEnd": dateOfEvacuation2,
            "evacuationLocation": formik.values.evacuationLocation?.label === "-" ? null : formik.values.evacuationLocation?.label,
            "status": formik.values.status?.label || null,
            "type": formik.values.type?.label || null,
            ...authParams,
        };

        console.log(data);
        setLoading(true);

        let res = await GetExportAsync(data);

        // Валидация запроса
        // if (!IsCode200(res)) {
        //     if (IsCode503(res)) {
        //         alertError('Сервис недоступен!');

        //         return;
        //     };

        //     alertWarn('Не удалось создать запись!');

        //     return;
        // }
        setLoading(false);
    };





    const model = {
        header: {
            thema: fields.collection2,
            label: 'Экспорт данных',
        },
        militaryUnit: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'militaryUnit',
            label: 'Воинская часть',
            elems: handbookMilitaryPart
        },

        dateOfInjury: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfInjury',
            label: 'Время ранения (от)',
        },
        birthday: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'birthday',
            label: 'Дата рождения',
        },
        dateOfInjury2: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfInjury2',
            label: 'Время ранения (до)',
        },

        location: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'location',
            label: 'Место оказания медицинской помощи',
            elems: handbookLocations
        },

        dateOfEvacuation: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfEvacuation',
            label: 'Время эвакуации / выписки (от)',
        },

        dateOfEvacuation2: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfEvacuation2',
            label: 'Время эвакуации / выписки (до)',
        },


        evacuationLocation: {
            thema: fields.collection2,
            size: 'small',
            name: 'evacuationLocation',
            label: 'Направление эвакуации',
            variant: 'outlined',
            elems: handbookEvacuationLocations,
        },

        status: {
            thema: fields.collection2,
            size: 'small',
            name: 'status',
            label: 'Статус',
            variant: 'outlined',
            elems: statusJson,
        },

        type: {
            thema: fields.collection2,
            size: 'small',
            name: 'type',
            label: 'Характериста диагноза',
            variant: 'outlined',
            elems: typesJson,
        },


        nextBtn: {
            thema: buttons.collection1,
            // icon: <MonitorHeartOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClickExport,
            value: 'Экспортировать',
        },
    }

    let context = {
        militaryUnit: '',
        dateOfInjury: null,
        birthday: null,
        dateOfInjury2: null,
        dateOfEvacuation: null,
        dateOfEvacuation2: null,
        location: '',
        evacuationLocation: '',
        status: null,
        type: null,
    }




    const handleFormAction = (values) => {
    };

    const validationSchema = Yup.object().shape({
    });


    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    const handleSetDate = (fieldName, val) => {
        let isValid = dayjs(val).isValid();
        console.log(fieldName, val);
        let dt = new Date().toISOString();
        // Валидация
        if (val != null && val !== undefined && isValid) {
            let tmp = new Date(val);
            let date = new Date(val);
            dt = tmp.getFullYear() === 1 ? dt : date.toISOString();
            const date1 = date.getDate();
            console.log(date1);
        } else {
            dt = null;
        }




        formik.setFieldValue(model[fieldName].name, dt);
    }


    const handleSetStateEvacuationLocation = (val) => {
        setEvacuationLocation(val);
    }

    const handleSetStateLocation = (val) => {
        setLocation(val);
    }

    const handleSetStateMilitaryUnit = (val) => {
        console.log(val);
        setMilitaryUnit(val);
    }

    const handleSetStatus = (val) => {
        setStatus(val);
    }

    const handleSetType = (val) => {
        setType(val);
    }


    useEffect(() => {

        MilitaryPartAsync().then((res) => {
            let items = res.items ? res.items.map(item => {
                return {
                    id: item,
                    label: item,
                };
            }
            ) : [];

            items = items.filter(item => item.label !== "-")

            items.unshift({
                id: "-",
                label: "-",
            });

            setHandbookMilitaryPart(items);
        });

        EvacuationLocationsAsync().then((res) => {
            let items = res.items ? res.items.map(item2 => {
                return {
                    id: item2,
                    label: item2,
                };
            }
            ) : [];

            items = items.filter(item => item.label !== "-")

            items.unshift({
                id: "-",
                label: "-",
            });


            setHandbookEvacuationLocations(items);
        });

        LocationsAsync().then((res) => {
            let items = res.items ? res.items.map(item3 => {
                return {
                    id: item3,
                    label: item3,
                };
            }
            ) : [];

            items = items.filter(item => item.label !== "-")

            items.unshift({
                id: "-",
                label: "-",
            });

            console.log(items);

            setHandbookLocations(items);
        });

    }, []);



    console.log(handbookMilitaryPart);
    console.log(handbookEvacuationLocations);

    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item
                xs={0}
                sm={0}
                md={0}
                lg={1}
                xl={2}
            />
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={8}
            >
                <form>
                    <Card
                        sx={{
                            mx: '5px',
                            py: '15px',
                            px: '10px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container
                            spacing={4}
                        >
                            <Grid
                                style={{
                                    textAlign: 'center',
                                }}
                                item
                                xs={12}
                            >
                                <Typography
                                    variant="h4"
                                    color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>

                            </Grid>
                            <>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <AutoCompleteCbx
                                        thema={model.militaryUnit.thema}
                                        name={model.militaryUnit.name}
                                        size={model.militaryUnit.size}
                                        variant={model.militaryUnit.variant}
                                        label={model.militaryUnit.label}
                                        value={formik.values.militaryUnit}
                                        handleBlur={formik.handleBlur}

                                        touched={formik.touched.militaryUnit}
                                        error={formik.errors.militaryUnit}


                                        handleSetState={handleSetStateMilitaryUnit}
                                        setFieldValue={formik.setFieldValue}
                                        val={militaryUnit}
                                        elems={model.militaryUnit?.elems}
                                    />

                                    {/* <TextFld
                                        thema={model.militaryUnit.thema}
                                        name={model.militaryUnit.name}
                                        size={model.militaryUnit.size}
                                        type={model.militaryUnit.type}
                                        variant={model.militaryUnit.variant}
                                        label={model.militaryUnit.label}
                                        value={formik.values.militaryUnit}
                                        handleBlur={formik.handleBlur}
                                        handleChange={formik.handleChange}
                                        touched={formik.touched.militaryUnit}
                                        error={formik.errors.militaryUnit}
                                    /> */}
                                </Grid>


                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <DateTimeFld
                                        thema={model.dateOfInjury.thema}
                                        name={model.dateOfInjury.name}
                                        size={model.dateOfInjury.size}
                                        variant={model.dateOfInjury.variant}
                                        label={model.dateOfInjury.label}
                                        value={formik.values.dateOfInjury}
                                        isDisabled={false}
                                        handleBlur={formik.handleBlur}
                                        handleChange={(val) => handleSetDate("dateOfInjury", val)}
                                        touched={formik.touched.dateOfInjury}
                                        error={formik.errors.dateOfInjury}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <DateTimeFld
                                        thema={model.dateOfInjury2.thema}
                                        name={model.dateOfInjury2.name}
                                        size={model.dateOfInjury2.size}
                                        variant={model.dateOfInjury2.variant}
                                        label={model.dateOfInjury2.label}
                                        value={formik.values.dateOfInjury2}
                                        isDisabled={false}
                                        handleBlur={formik.handleBlur}
                                        handleChange={(val) => handleSetDate("dateOfInjury2", val)}
                                        touched={formik.touched.dateOfInjury2}
                                        error={formik.errors.dateOfInjury2}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <AutoCompleteCbx
                                        thema={model.location.thema}
                                        name={model.location.name}
                                        size={model.location.size}
                                        elems={model.location.elems}
                                        variant={model.location.variant}
                                        label={model.location.label}
                                        value={formik.values.location}
                                        val={location}
                                        handleBlur={formik.handleBlur}
                                        setFieldValue={formik.setFieldValue}
                                        handleSetState={handleSetStateLocation}
                                        touched={formik.touched.location}
                                        error={formik.errors.location}
                                    />
                                </Grid>




                                <Grid
                                    item
                                    xs={12}
                                >
                                    <AutoCompleteCbx
                                        thema={model.evacuationLocation.thema}
                                        name={model.evacuationLocation.name}
                                        size={model.evacuationLocation.size}
                                        elems={model.evacuationLocation.elems}
                                        variant={model.evacuationLocation.variant}
                                        label={model.evacuationLocation.label}
                                        value={formik.values.evacuationLocation}
                                        val={evacuationLocation}
                                        handleBlur={formik.handleBlur}
                                        setFieldValue={formik.setFieldValue}
                                        handleSetState={handleSetStateEvacuationLocation}
                                        touched={formik.touched.evacuationLocation}
                                        error={formik.errors.evacuationLocation}
                                    />
                                </Grid>




                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <DateTimeFld
                                        thema={model.dateOfEvacuation.thema}
                                        name={model.dateOfEvacuation.name}
                                        size={model.dateOfEvacuation.size}
                                        type={model.dateOfEvacuation.type}
                                        variant={model.dateOfEvacuation.variant}
                                        label={model.dateOfEvacuation.label}
                                        value={formik.values.dateOfEvacuation}
                                        handleBlur={formik.handleBlur}
                                        handleChange={(val) => handleSetDate("dateOfEvacuation", val)}
                                        touched={formik.touched.dateOfEvacuation}
                                        error={formik.errors.dateOfEvacuation}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <DateTimeFld
                                        thema={model.dateOfEvacuation2.thema}
                                        name={model.dateOfEvacuation2.name}
                                        size={model.dateOfEvacuation2.size}
                                        type={model.dateOfEvacuation2.type}
                                        variant={model.dateOfEvacuation2.variant}
                                        label={model.dateOfEvacuation2.label}
                                        value={formik.values.dateOfEvacuation2}
                                        handleBlur={formik.handleBlur}
                                        handleChange={(val) => handleSetDate("dateOfEvacuation2", val)}
                                        touched={formik.touched.dateOfEvacuation2}
                                        error={formik.errors.dateOfEvacuation2}
                                    />
                                </Grid>



                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <AutoCompleteCbx
                                        thema={model.status.thema}
                                        name={model.status.name}
                                        size={model.status.size}
                                        elems={model.status.elems}
                                        variant={model.status.variant}
                                        label={model.status.label}
                                        value={formik.values.status}
                                        val={status}
                                        handleBlur={formik.handleBlur}
                                        setFieldValue={formik.setFieldValue}
                                        handleSetState={handleSetStatus}
                                        touched={formik.touched.status}
                                        error={formik.errors.status}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <AutoCompleteCbx
                                        thema={model.type.thema}
                                        name={model.type.name}
                                        size={model.type.size}
                                        elems={model.type.elems}
                                        variant={model.type.variant}
                                        label={model.type.label}
                                        value={formik.values.type}
                                        val={type}
                                        handleBlur={formik.handleBlur}
                                        setFieldValue={formik.setFieldValue}
                                        handleSetState={handleSetType}
                                        touched={formik.touched.type}
                                        error={formik.errors.type}
                                    />
                                </Grid>

                                {/* <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                >
                                    <DateTimeFld
                                        thema={model.birthday.thema}
                                        name={model.birthday.name}
                                        size={model.birthday.size}
                                        type={model.birthday.type}
                                        variant={model.birthday.variant}
                                        label={model.birthday.label}
                                        value={formik.values.birthday}
                                        handleBlur={formik.handleBlur}
                                        handleChange={(val) => handleSetDate("birthday", val)}
                                        touched={formik.touched.birthday}
                                        error={formik.errors.birthday}
                                        views={['year', 'month', 'day']}
                                    />
                                </Grid> */}

                            </>

                            <Grid
                                item
                                xs={0}
                                sm={8}
                            // md={10}
                            />
                            <Grid
                                item
                                xs={12}
                                sm={4}
                            // md={3}
                            >
                                {false === true ?
                                    <CircularRightPrg /> :
                                    <EndIconBtn
                                        thema={model.nextBtn.thema}
                                        icon={model.nextBtn.icon}
                                        variant={model.nextBtn.variant}
                                        value={model.nextBtn.value}
                                        handleClick={model.nextBtn.handleClick}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </Grid>


        </Grid>
    )
}
